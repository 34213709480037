import React, { useState, useEffect } from "react";
import { Table, Button, Form, Modal, Spinner } from "react-bootstrap"; // Import Form component
import BootstrapTable from "react-bootstrap-table-next";
import paginationFactory from "react-bootstrap-table2-paginator";
import "bootstrap/dist/css/bootstrap.min.css";
import {
  activatePharmacy,
  deletePharmacy,
  disactivatePharmacy,
  fetchPharmacies,
} from "./apiRoute";
import { Header } from "../components";
import { useStateContext } from "../contexts/ContextProvider";
import { Confirm, Info } from "../components/Alert.js";
import { BsBoxArrowUpRight } from "react-icons/bs";

const Pharmacies = () => {
  const { isActive, userPreviledges } = useStateContext();
  const [pharmacies, setPharmacies] = useState([]);
  const [searchTerm, setSearchTerm] = useState(""); // State for search term
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    const fetchData = async () => {
      try {
        setLoading(true);
        const response = await fetchPharmacies();
        setPharmacies(response.data.result);
      } catch (error) {
        console.error("Error fetching data:", error);
      } finally {
        setLoading(false);
      }
    };
    fetchData();
  }, []);

  const [showModal, setShowModal] = useState(false);
  const [selectedPharmacy, setSelectedPharmacy] = useState(null);

  // Filter the data to get only the inactive pharmacy
  const inactivePharmacy = pharmacies.filter(
    (item) => item.status === "Inactive"
  );

  // Filter the data to get only the active pharmacy
  const activePharmacy = pharmacies.filter((item) => item.status === "Active");

  const handleModal = (user) => {
    setSelectedPharmacy(user);
    setShowModal(true);
  };

  const handleClose = () => {
    setShowModal(false);
    setSelectedPharmacy(null);
  };

  const enablePharmacy = async (id, storeName) => {
    const confirmResult = await Confirm(
      "Confirm Activation",
      `Do you want to activate ${storeName} ?`
    );
    if (confirmResult.isConfirmed) {
      try {
        setLoading(true);
        const response = await activatePharmacy(id, storeName);
        Info("Success", response.message);
        handleClose();
        const responseAgain = await fetchPharmacies();
        setPharmacies(responseAgain.data.result);
      } catch (error) {
        console.log(error);
        Info("Error", error.response.data.error);
      } finally {
        setLoading(false);
      }
    }
  };

  const disAblePharmacy = async (id, storeName) => {
    const confirmResult = await Confirm(
      "Confirm Disactivation",
      `Do you want to disactivate ${storeName} ?`
    );
    if (confirmResult.isConfirmed) {
      try {
        setLoading(true);
        const response = await disactivatePharmacy(id);
        Info("Success", response.message);
        handleClose();
        const responseAgain = await fetchPharmacies();
        setPharmacies(responseAgain.data.result);
      } catch (error) {
        console.log(error);
        Info("Error", error.response.data.error);
      } finally {
        setLoading(false);
      }
    }
  };

  const handleDelete = async (id, name) => {
    const confirmResult = await Confirm(
      "Confirm Delete",
      `Do you want to delete ${name} from Nkomor database`
    );
    if (confirmResult.isConfirmed) {
      try {
        setLoading(true);
        const response = await deletePharmacy(id);
        Info("Success", response.data.message);
        handleClose();
        const responseAgain = await fetchPharmacies();
        setPharmacies(responseAgain.data.result);
      } catch (error) {
        console.log(error);
        Info("Error", error.response.data.error);
      } finally {
        setLoading(false);
      }
    }
  };

  const formatDate = (timestamp) => {
    if (timestamp <= 0) {
      return "";
    }

    const date = new Date(timestamp);
    const day = date.getDate();
    const month = date.toLocaleString("default", { month: "long" });
    const year = date.getFullYear();
    return `${day}-${month}-${year}`;
  };

  const formatterColor = (cell, row) => {
    let status = "";
    let backgroundColor = "";

    if (cell === "Active") {
      status = "Active";
      backgroundColor = "green";
    } else {
      status = "Inactive";
      backgroundColor = "red";
    }

    return (
      <div
        style={{
          backgroundColor: backgroundColor,
          color: "white",
          padding: "5px",
          borderRadius: "5px",
          textAlign: "center",
        }}
      >
        {status}
      </div>
    );
  };

  const columns = [
    {
      dataField: "count",
      text: "#",
      formatter: (cell, row, rowIndex) => rowIndex + 1,
      headerStyle: { width: "50px", backgroundColor: "#99E6E3" },
    },
    {
      dataField: "business_name",
      text: "Name",
      sort: true,
      headerStyle: {
        backgroundColor: "#99E6E3",
      },
    },
    {
      dataField: "business_phone",
      text: "Phone Number",
      sort: true,
      headerStyle: {
        backgroundColor: "#99E6E3",
      },
    },
    {
      dataField: "store_name",
      text: "Store Name",
      sort: true,
      headerStyle: {
        backgroundColor: "#99E6E3",
      },
    },
    {
      dataField: "status",
      text: "Status",
      formatter: formatterColor,
      sort: true,
      headerStyle: {
        backgroundColor: "#99E6E3",
      },
    },
    {
      dataField: "time",
      text: "Date",
      sort: true,
      formatter: (cell, row) => formatDate(cell),
      headerStyle: {
        backgroundColor: "#99E6E3",
      },
    },
    {
      dataField: "id",
      text: "View",
      sort: true,
      headerStyle: {
        backgroundColor: "#99E6E3",
      },
      formatter: (cell, row) => (
        <>
          <Button
            variant="danger"
            title="Profile"
            onClick={() => handleModal(row)}
          >
            <BsBoxArrowUpRight />
          </Button>
        </>
      ),
    },
  ];

  const filteredData = pharmacies.filter(
    (user) =>
      // Filter based on search term
      user.business_name.toLowerCase().includes(searchTerm.toLowerCase()) ||
      user.business_phone.toLowerCase().includes(searchTerm.toLowerCase()) ||
      user.store_name.toLowerCase().includes(searchTerm.toLowerCase()) ||
      user.business_location.toLowerCase().includes(searchTerm.toLowerCase()) ||
      user.status.toLowerCase().includes(searchTerm.toLowerCase())
  );

  return (
    <>
      {isActive === true ? (
        <div className="m-2 md:m-10 mt-24 p-4 md:p-10 bg-[#E6FFFD] rounded-3xl">
          <Header title="Pharmacies" />
          <div className="flex gap-10 mb-8">
            <div className="w-1/3 p-4 bg-white rounded-lg shadow-md font-semibold">
              Total Pharmacies <br />
              <span className="font-extrabold text-xl">
                {pharmacies.length}
              </span>
            </div>
            <div className="w-1/3 p-4 bg-white rounded-lg shadow-md font-semibold">
              Active Pharmacies <br />
              <span className="font-extrabold text-xl">
                {activePharmacy.length}
              </span>
            </div>
            <div className="w-1/3 p-4 bg-white rounded-lg shadow-md font-semibold">
              Inactive Pharmacies <br />
              <span className="font-extrabold text-xl">
                {inactivePharmacy.length}
              </span>
            </div>
          </div>
          <Form.Control
            type="text"
            className="mb-3"
            placeholder="Search for pharmacy..."
            value={searchTerm}
            style={{ width: "300px", float: "right" }}
            onChange={(e) => setSearchTerm(e.target.value)}
          />
          <div style={{ cursor: "pointer" }}>
            {loading ? (
              <Spinner animation="border" size="sm" />
            ) : (
              <BootstrapTable
                keyField="email"
                data={filteredData.sort((a, b) => b.id - a.id)} // Use filteredData instead of pharmacies
                columns={columns}
                bordered={false}
                pagination={paginationFactory()}
              />
            )}
          </div>
        </div>
      ) : (
        <div>User not logged in</div>
      )}

      <Modal show={showModal} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>Pharmacy Info</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {selectedPharmacy && (
            <div>
              <p>
                <strong>Name:</strong> {selectedPharmacy.business_name}
              </p>
              <p>
                <strong>Phone Number:</strong> {selectedPharmacy.business_phone}
              </p>
              <p>
                <strong>Store Name:</strong> {selectedPharmacy.store_name}
              </p>
              <p>
                <strong>Email:</strong> {selectedPharmacy.pharmacy_email}
              </p>
              <p>
                <strong>Registration Number:</strong>{" "}
                {selectedPharmacy.pharmacy_number}
              </p>
              <p>
                <strong>Status:</strong> {selectedPharmacy.status}
              </p>
              <p>
                <strong>Location:</strong> {selectedPharmacy.business_location}
              </p>
              <p>
                <strong>Date and Time:</strong> {selectedPharmacy.time}
              </p>

              {loading ? (
                <Spinner animation="border" size="sm" />
              ) : (
                <>
                  <Button
                    variant="primary"
                    title="Activate pharmacy"
                    value="Activate Pharmacy"
                    style={{ marginRight: "10px", marginLeft: "10px" }}
                    onClick={() =>
                      enablePharmacy(
                        selectedPharmacy.id,
                        selectedPharmacy.store_name
                      )
                    }
                  >
                    Activate Store
                  </Button>
                  <Button
                    variant="info"
                    title="Disable Pharmacy"
                    value="Disable Pharmacy"
                    onClick={() =>
                      disAblePharmacy(
                        selectedPharmacy.id,
                        selectedPharmacy.store_name
                      )
                    }
                  >
                    Disactivate Store
                  </Button>
                </>
              )}
            </div>
          )}

          {loading ? (
            <Spinner animation="border" size="sm" />
          ) : (
            <>
              {userPreviledges.can_delete === true ? (
                <Button
                  variant="danger"
                  title="Delete Pharmacy"
                  value="Delete Pharmacy"
                  style={{ marginTop: "10px" }}
                  onClick={() =>
                    handleDelete(
                      selectedPharmacy.id,
                      selectedPharmacy.business_name
                    )
                  }
                >
                  Delete Pharmacy
                </Button>
              ) : (
                ""
              )}
            </>
          )}
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            Close
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default Pharmacies;
