import React, { useState, useEffect } from "react";
import { Table, Button, Form, Modal, Spinner } from "react-bootstrap"; // Import Form component
import BootstrapTable from "react-bootstrap-table-next";
import paginationFactory from "react-bootstrap-table2-paginator";
import "bootstrap/dist/css/bootstrap.min.css";
import {
  fetchUsersData,
  makeAdmin,
  validateAccount,
  revokeAdmin,
  deleteUser,
} from "./apiRoute";
import { Header } from "../components";
import { useStateContext } from "../contexts/ContextProvider";
import { Confirm, Info } from "../components/Alert.js";
import { BsBoxArrowUpRight } from "react-icons/bs";

const Users = () => {
  const { isActive, userPreviledges } = useStateContext();
  const [refillData, setRefillData] = useState([]);
  const [searchTerm, setSearchTerm] = useState(""); // State for search term
  const [selectedFilter, setSelectedFilter] = useState(""); // State for select option
  const [waiting, setWaiting] = useState(false);
  const [adminEmail, setAdminEmail] = useState("");
  const [loading, setLoading] = useState(false);
  const [userCount, setUserCount] = useState("");
  const [privileges, setPrivileges] = useState({
    "lab-services": false,
    users: false,
    subscribers: false,
    coupon: false,
    export: false,
    "prescription-orders": false,
    "insurance-orders": false,
    "paystack-api": false,
    "medicine-store": false,
    "file-manager": false,
    "app-messages": false,
    "log-out": false,
    "login-history": false,
    pharmacies: false,
    drugs: false,
    admin: false,
    dashboard: false,
    can_view: false,
    can_edit: false,
    can_delete: false,
  });

  useEffect(() => {
    const fetchData = async () => {
      try {
        setLoading(true);
        const response = await fetchUsersData();
        setRefillData(response.data.data.user);
        setUserCount(response.data.data.user.length);
      } catch (error) {
        console.error("Error fetching data:", error);
      } finally {
        setLoading(false);
      }
    };
    fetchData();
  }, []);

  const [showModal, setShowModal] = useState(false);
  const [selectedUser, setSelectedUser] = useState(null);

  const activeUsers = refillData.filter((user) => user.reg_complete === 2);
  const incompleteUsers = refillData.filter((user) => user.reg_complete !== 2);

  const handleModal = (user) => {
    setSelectedUser(user);
    setShowModal(true);
  };

  const handleClose = () => {
    setShowModal(false);
    setSelectedUser(null);
  };

  const handleAdmins = async (email) => {
    try {
      setLoading(true);
      const response = await makeAdmin(email, JSON.stringify(privileges));
      const responseAgain = await fetchUsersData();
      setRefillData(responseAgain.data.data.user);
      Info("Success", response.data.message);
      setShowModal(false);
      setShowUsageModal(false);
    } catch (error) {
      console.log(error);
      Info("Error", error.response.data.error);
    } finally {
      setLoading(false);
    }
  };

  const handleAdmin = async (email) => {
    const confirmResult = await Confirm(
      "Confirm Operation",
      `Do you want to proceed with this operation`
    );
    if (confirmResult.isConfirmed) {
      try {
        setShowUsageModal(true);

        setAdminEmail(email);
      } catch (error) {
        console.log(error);
        Info("Error", error.response.data.error);
      }
    }
  };

  const [showUsageModal, setShowUsageModal] = useState(false);

  const handleUsageModal = (user) => {
    setShowUsageModal(true);
  };

  const handleUsageClose = () => {
    setShowUsageModal(false);
  };

  const handleAdminRevoke = async (email) => {
    const confirmResult = await Confirm(
      "Confirm Revoke",
      `Do you want to proceed with this operation`
    );
    if (confirmResult.isConfirmed) {
      try {
        setLoading(true);
        const response = await revokeAdmin(email);
        Info("Success", response.data.message);
      } catch (error) {
        console.log(error);
        Info("Error", error.response.data.error);
      } finally {
        setLoading(false);
      }
    }
  };

  const handleDelete = async (email) => {
    const confirmResult = await Confirm(
      "Confirm Delete",
      `Do you want to delete ${email} from Nkomor database`
    );
    if (confirmResult.isConfirmed) {
      try {
        setLoading(true);
        const response = await deleteUser(email);
        Info("Success", response.data.message);
        handleClose();
        const responseAgain = await fetchUsersData();
        setRefillData(responseAgain.data.data.user);
      } catch (error) {
        console.log(error);
        Info("Error", error.response.data.error);
      } finally {
        setLoading(false);
      }
    }
  };

  const formatDate = (timestamp) => {
    if (timestamp <= 0) {
      return "";
    }

    const date = new Date(timestamp);
    const day = date.getDate();
    const month = date.toLocaleString("default", { month: "long" });
    const year = date.getFullYear();
    return `${day}-${month}-${year}`;
  };

  const formatterColor = (cell, row) => {
    let status = "";
    let backgroundColor = "";

    if (cell === 2) {
      status = "Complete";
      backgroundColor = "green";
    } else if (cell === 1 || cell === 0) {
      status = "Incomplete";
      backgroundColor = "red";
    } else if (cell === "staff") {
      status = "Staff";
      backgroundColor = "blue";
    } else if (cell === "user") {
      status = "User";
      backgroundColor = "purple";
    }

    return (
      <div
        style={{
          backgroundColor: backgroundColor,
          color: "white",
          padding: "5px",
          borderRadius: "5px",
          textAlign: "center",
        }}
      >
        {status}
      </div>
    );
  };

  const formatterAdmin = (cell, row) => {
    let status = "";
    let backgroundColor = "";

    if (cell === 1) {
      status = "Yes";
      backgroundColor = "green";
    } else {
      status = "No";
      backgroundColor = "red";
    }

    return (
      <div
        style={{
          backgroundColor: backgroundColor,
          color: "white",
          padding: "5px",
          borderRadius: "5px",
        }}
      >
        {status}
      </div>
    );
  };

  const columns = [
    {
      dataField: "count",
      text: "#",
      formatter: (cell, row, rowIndex) => rowIndex + 1,
      headerStyle: { width: "50px", backgroundColor: "#99E6E3" },
    },
    {
      dataField: "timein",
      text: "Reg Date",
      formatter: (cell, row) => formatDate(cell),
      headerStyle: {
        backgroundColor: "#99E6E3",
      },
    },
    {
      dataField: "email",
      text: "Email",
      sort: true,
      headerStyle: {
        backgroundColor: "#99E6E3",
      },
    },
    {
      dataField: "firstname",
      text: "Full Name",
      sort: true,
      headerStyle: {
        backgroundColor: "#99E6E3",
      },
      formatter: (cell, row) => (
        <>
          {row.firstname} {row.lastname}
        </>
      ),
    },
    {
      dataField: "reg_complete",
      text: "Reg Status",
      sort: true,
      formatter: formatterColor,
      headerStyle: {
        backgroundColor: "#99E6E3",
      },
    },
    {
      dataField: "user_status",
      text: "User Status",
      sort: true,
      formatter: formatterColor,
      headerStyle: {
        backgroundColor: "#99E6E3",
      },
    },

    {
      dataField: "id",
      text: "View",
      sort: true,
      headerStyle: {
        backgroundColor: "#99E6E3",
      },
      formatter: (cell, row) => (
        <>
          <Button
            variant="danger"
            title="Profile"
            onClick={() => handleModal(row)}
          >
            <BsBoxArrowUpRight />
          </Button>
        </>
      ),
    },

    // {
    //     dataField: 'make_admin',
    //     text: 'Upgrade',
    //     formatter: (cell, row) => (
    //       <>
    //         <Button variant="danger" title="Make admin" onClick={() => handleAdmin(row.email)}>
    //           <FaUserAlt />
    //         </Button>

    //       </>

    //     )
    //   },
    // {
    //   dataField: 'reg_complete',
    //   text: 'Resend Link',
    //   formatter: (cell, row) => (
    //   <>
    //     {row.reg_complete !== 2 && (
    //       <Button variant="danger" title="Validate" onClick={() => handleResend(row.email)}>
    //         <FaLink />
    //       </Button>
    //     )}
    //   </>
    // )
    // }
  ];

  const handleResend = async (email) => {
    const confirmResult = await Confirm(
      "Confirm Operation",
      `Do you want to proceed with this operation`
    );
    if (confirmResult.isConfirmed) {
      try {
        setLoading(true);
        const response = await validateAccount(email);
        console.log(response);
      } catch (error) {
        console.log(error);
      } finally {
        setLoading(false);
        Info("Activation Link", "Activation link has been resent to the user");
      }
    }
  };

  const filteredData = refillData.filter((user) => {
    // Filter based on search term
    if (
      user.email.toLowerCase().includes(searchTerm.toLowerCase()) ||
      user.firstname.toLowerCase().includes(searchTerm.toLowerCase()) ||
      user.lastname.toLowerCase().includes(searchTerm.toLowerCase()) ||
      user.address.toLowerCase().includes(searchTerm.toLowerCase()) ||
      user.phone.toLowerCase().includes(searchTerm.toLowerCase()) ||
      user.town_name.toLowerCase().includes(searchTerm.toLowerCase()) ||
      user.postalcode.toLowerCase().includes(searchTerm.toLowerCase()) ||
      user.gender.toLowerCase().includes(searchTerm.toLowerCase())
    ) {
      // If search term matches, then apply the selected filter
      if (selectedFilter === "1" && user.reg_complete === 2) {
        return true; // Complete
      } else if (
        selectedFilter === "2" &&
        (user.reg_complete === 1 || user.reg_complete === 0)
      ) {
        return true; // Incomplete
      } else if (selectedFilter === "staff" && user.user_status === "staff") {
        return true; // Staff
      }
    }
  });

  const handleCheckboxChange = (e) => {
    const { name, checked } = e.target;
    setPrivileges((prevState) => ({
      ...prevState,
      [name]: checked,
    }));
  };

  const handleSave = () => {
    // Save the privileges object as needed
    console.log("User Privileges:", privileges);
    setWaiting(true);
    handleUsageClose();
    handleAdmins(adminEmail);
  };

  return (
    <>
      {isActive === true ? (
        <div className="m-2 md:m-10 mt-24 p-4 md:p-10 bg-[#E6FFFD] rounded-3xl">
          <Header title="Users" />
          <div className="flex justify-around gap-10 mb-8">
            <div className="w-1/3 p-4 bg-white rounded-lg shadow-md font-semibold">
              Total Users <br />
              <span className="font-extrabold text-xl">{userCount}</span>
            </div>
            <div className="w-1/3 p-4 bg-white rounded-lg shadow-md font-semibold">
              Total Active Users <br />
              <span className="font-extrabold text-xl">
                {activeUsers.length}
              </span>
            </div>
            <div className="w-1/3 p-4 bg-white rounded-lg shadow-md font-semibold">
              Incomplete Registrations <br />
              <span className="font-extrabold text-xl">
                {incompleteUsers.length}
              </span>
            </div>
          </div>
          <div className="flex justify-between mb-3">
            <Form.Select
              aria-label="Default select example"
              className="w-auto"
              value={selectedFilter} // Bind selected value to state
              onChange={(e) => setSelectedFilter(e.target.value)} // Update state on change
            >
              <option>Filter</option>
              <option value="1">Complete</option>
              <option value="2">Incomplete</option>
              <option value="staff">Staff</option>
            </Form.Select>
            <Form.Control
              type="text"
              placeholder="Search for users"
              value={searchTerm}
              style={{ width: "300px", float: "right" }}
              onChange={(e) => setSearchTerm(e.target.value)}
            />
          </div>
          <div style={{ cursor: "pointer" }}>
            {loading ? (
              <Spinner animation="border" size="sm" />
            ) : (
              <BootstrapTable
                keyField="email"
                data={filteredData.length == 0 ? refillData : filteredData} // Use filteredData instead of refillData
                columns={columns}
                bordered={false}
                pagination={paginationFactory()}
              />
            )}
          </div>
        </div>
      ) : (
        <div>User not logged in</div>
      )}

      <Modal show={showModal} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>User Profile</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {selectedUser && (
            <div>
              <p>
                <strong>First Name:</strong> {selectedUser.firstname}
              </p>
              <p>
                <strong>Last Name:</strong> {selectedUser.lastname}
              </p>
              <p>
                <strong>Email:</strong> {selectedUser.email}
              </p>
              <p>
                <strong>Address:</strong> {selectedUser.address}
              </p>
              <p>
                <strong>Phone Number:</strong> {selectedUser.phone}
              </p>
              <p>
                <strong>Twon Name:</strong> {selectedUser.town_name}
              </p>
              <p>
                <strong>Postal Code:</strong> {selectedUser.postalcode}
              </p>
              <p>
                <strong>Gender:</strong> {selectedUser.gender}
              </p>
              {selectedUser.reg_complete !== 2 &&
                (loading ? (
                  <Spinner animation="border" size="sm" />
                ) : (
                  <>
                    <Button
                      variant="success"
                      title="Validate"
                      onClick={() => handleResend(selectedUser.email)}
                    >
                      Resend Link
                    </Button>
                  </>
                ))}

              {loading ? (
                <Spinner animation="border" size="sm" />
              ) : (
                <>
                  <Button
                    variant="primary"
                    title="Make admin"
                    value="Make Admin"
                    style={{ marginRight: "10px", marginLeft: "10px" }}
                    onClick={() => handleAdmin(selectedUser.email)}
                  >
                    Make Admin
                  </Button>
                  <Button
                    variant="info"
                    title="Revoke Access"
                    value="Revoke Access"
                    onClick={() => handleAdminRevoke(selectedUser.email)}
                  >
                    Revoke Access
                  </Button>
                </>
              )}
            </div>
          )}

          {loading ? (
            <Spinner animation="border" size="sm" />
          ) : (
            <>
              {userPreviledges.can_delete === true ? (
                <Button
                  variant="danger"
                  title="Delete User"
                  value="Delete User"
                  style={{ marginTop: "10px" }}
                  onClick={() => handleDelete(selectedUser.email)}
                >
                  Delete User
                </Button>
              ) : (
                ""
              )}
            </>
          )}
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            Close
          </Button>
        </Modal.Footer>
      </Modal>

      <Modal show={showUsageModal} onHide={handleUsageClose}>
        <Modal.Header closeButton>
          <Modal.Title>User Privileges</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form>
            {Object.keys(privileges).map((module) => (
              <Form.Group
                controlId={`formBasicCheckbox-${module}`}
                key={module}
              >
                <Form.Check
                  type="checkbox"
                  label={module.toUpperCase()}
                  name={module}
                  checked={privileges[module]}
                  onChange={handleCheckboxChange}
                />
              </Form.Group>
            ))}
          </Form>
        </Modal.Body>
        <Modal.Footer>
          {loading ? (
            <Spinner animation="border" size="sm" />
          ) : (
            <>
              <Button variant="secondary" onClick={handleUsageClose}>
                Close
              </Button>
              <Button variant="primary" onClick={handleSave}>
                Save Changes
              </Button>
            </>
          )}
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default Users;
