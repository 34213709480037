import React, { useEffect, useState } from "react";
import { Button, Form, Modal, Spinner } from "react-bootstrap";
import BootstrapTable from "react-bootstrap-table-next";
import paginationFactory from "react-bootstrap-table2-paginator";
import { useStateContext } from "../contexts/ContextProvider";
import { Header } from "../components";
import { fetchLabServiceData } from "./apiRoute";
import { BsBoxArrowUpRight } from "react-icons/bs";

function LabServices() {
  const { isActive } = useStateContext();
  const [searchTerm, setSearchTerm] = useState(""); // State for search term
  const [loading, setLoading] = useState(false);
  const [labServiceData, setLabServiceData] = useState([]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        setLoading(true);
        const response = await fetchLabServiceData();
        setLabServiceData(response.data.data.user);
      } catch (error) {
        console.error("Error fetching data:", error);
      } finally {
        setLoading(false);
      }
    };
    fetchData();
  }, []);

  const [showModal, setShowModal] = useState(false);
  const [selectedUser, setSelectedUser] = useState(null);

  const handleModal = (user) => {
    setSelectedUser(user);
    setShowModal(true);
  };

  const handleClose = () => {
    setShowModal(false);
    setSelectedUser(null);
  };

  const formatterColor = (cell, row) => {
    let status = "";
    let backgroundColor = "";

    if (row.is_payment === "True") {
      status = "Completed";
      backgroundColor = "green";
    } else {
      status = "Pending";
      backgroundColor = "orange";
    }

    return (
      <div
        style={{
          backgroundColor: backgroundColor,
          color: "white",
          padding: "5px",
          borderRadius: "5px",
          textAlign: "center",
        }}
      >
        {status}
      </div>
    );
  };

  const columns = [
    {
      dataField: "count",
      text: "#",
      formatter: (cell, row, rowIndex) => rowIndex + 1,
      headerStyle: { width: "50px", backgroundColor: "#99E6E3" },
    },
    {
      dataField: "orderNumber",
      text: "Order Number",
      sort: true,
      headerStyle: {
        backgroundColor: "#99E6E3",
      },
    },
    {
      dataField: "test_name",
      text: "Test",
      sort: true,
      headerStyle: {
        backgroundColor: "#99E6E3",
      },
    },
    {
      dataField: "is_payment",
      text: "Status",
      sort: true,
      formatter: formatterColor,
      headerStyle: {
        backgroundColor: "#99E6E3",
      },
    },
    {
      dataField: "id",
      text: "Action ",
      headerStyle: {
        backgroundColor: "#99E6E3",
      },
      formatter: (cell, row) => (
        <>
          <Button
            variant="danger"
            title="Profile"
            onClick={() => handleModal(row)}
          >
            <BsBoxArrowUpRight />
          </Button>
        </>
      ),
    },
  ];

  // Filter data based on search term
  const filteredData = labServiceData.filter(
    (service) =>
      service.test_name.toLowerCase().includes(searchTerm.toLowerCase()) ||
      service.orderNumber.toLowerCase().includes(searchTerm.toLowerCase())
  );

  // Lab Revenue
  const labRevenue = labServiceData
    .filter((lab) => lab.is_payment === "True")
    .reduce((total, item) => total + item.price, 0)
    .toFixed(2);

  const totalLabTests = labServiceData.length;

  const processedLab = labServiceData.filter(
    (lab) => lab.is_payment === "True"
  ).length;

  const pendingLab = labServiceData.filter(
    (lab) => lab.is_payment === "False"
  ).length;

  return (
    <>
      {isActive === true ? (
        <div className="m-2 md:m-10 mt-24 p-4 md:p-10 bg-[#E6FFFD] rounded-3xl ">
          <Header title="Lab Tests" />
          <div className="flex justify-start gap-10 mb-8">
            <div className="w-1/3 p-4 bg-white rounded-lg shadow-md font-semibold">
              Total Labs Requested <br />
              <span className="font-extrabold text-xl">{totalLabTests}</span>
            </div>
            <div className="w-1/3 p-4 bg-white rounded-lg shadow-md font-semibold">
              Total Labs Processed <br />
              <span className="font-extrabold text-xl">{processedLab}</span>
            </div>
            <div className="w-1/3 p-4 bg-white rounded-lg shadow-md font-semibold">
              Total Labs Pending <br />
              <span className="font-extrabold text-xl">{pendingLab}</span>
            </div>
          </div>
          <div className="flex justify-start gap-10 mb-8">
            <div className="w-1/3 p-4 bg-white rounded-lg shadow-md font-semibold">
              Labs Revenue <br />
              <span className="font-extrabold text-xl">₵ {labRevenue}</span>
            </div>
          </div>
          {/* Search input */}
          <Form.Control
            type="text"
            placeholder="Search for Order..."
            className="mb-3"
            value={searchTerm}
            style={{ width: "300px", float: "right" }}
            onChange={(e) => setSearchTerm(e.target.value)}
          />
          <div>
            {loading ? (
              <Spinner animation="border" size="sm" />
            ) : (
              <BootstrapTable
                keyField="id"
                data={filteredData} // Use filteredData instead of refillData
                columns={columns}
                bordered={false}
                pagination={paginationFactory()}
              />
            )}
          </div>
        </div>
      ) : (
        ""
      )}
      <Modal show={showModal} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>Lab Test Informations</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {selectedUser && (
            <div>
              <p>
                <strong>Order Number:</strong> {selectedUser.orderNumber}
              </p>
              <p>
                <strong>Facility:</strong> {selectedUser.facility}
              </p>
              <p>
                <strong>Test:</strong> {selectedUser.test_name}
              </p>
              <p>
                <strong>Price:</strong> {selectedUser.price}
              </p>
              <p>
                <strong>Phone Number:</strong> {selectedUser.phoneNumber}
              </p>
              <p>
                <strong>Location:</strong> {selectedUser.labLocation}
              </p>
              <p>
                <strong>Payment Status:</strong> {selectedUser.is_payment}
              </p>
              <p>
                <strong>Payment Info:</strong> {selectedUser.payment_info}
              </p>
            </div>
          )}
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            Close
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
}

export default LabServices;
