import apiRequest from "./apiRequest";

async function fetchRefillData() {
  const response = await apiRequest("GET", `api/admin/refill_data/`);
  return response;
}

async function fetchSubscribersData() {
  const response = await apiRequest("GET", `api/admin/subscribers_data/`);
  return response;
}

async function fetchUsersData() {
  const response = await apiRequest("GET", `api/admin/user_data/`);
  return response;
}

async function fetchPharmacies() {
  const response = await apiRequest("GET", `api/storefront/get-all-pharmacies/`);
  return response;
}

async function fetchDrugs(pharmacy) {
  const response = await apiRequest("GET", `api/storefront/get-all-drugs/${pharmacy}`);
  return response;
}

async function fetchAdminData() {
  const response = await apiRequest("GET", `api/admin/admin_data/`);
  return response;
}

async function fetchInsuranceData() {
  const response = await apiRequest("GET", `api/admin/bima_data/`);
  return response;
}

async function fetchLabServiceData() {
  const response = await apiRequest("GET", `api/admin/get-lab-test/`);
  return response;
}

async function fetchPaystackData() {
  const response = await apiRequest("GET", `api/admin/paystack_data/`);
  return response;
}

async function fetchLoginData() {
  const response = await apiRequest("GET", `api/users/get-login-history/`);
  return response;
}

async function fetchAdminLoginData() {
  const response = await apiRequest("GET", `api/admin/get-login-history/`);
  return response;
}

async function fetchCouponData() {
  const response = await apiRequest("GET", `api/admin/coupon_data/`);
  return response;
}

async function fetchContactData() {
  const response = await apiRequest("GET", `api/admin/contact_data/`);
  return response;
}

async function MedicineStore() {
  const response = await apiRequest("GET", `api/admin/get-medicine/`);
  return response;
}

async function MedicineStoreOrders() {
  const response = await apiRequest("GET", `api/admin/get-medicine-orders/`);
  return response;
}

async function userLogin(email, password) {
  const data = { email: email, password: password };
  const response = await apiRequest("POST", `api/admin/admin/login`, data);
  return response;
}

async function storeCoupon(promoCode, amount, couponType) {
  const data = { promoCode, amount, couponType };
  const response = await apiRequest("POST", `api/admin/promo-code`, data);
  return response;
}

async function storeMedicine(medicineName, amount, image) {
  const data = { medicineName, amount, image };
  const response = await apiRequest("POST", `api/admin/store-medicine`, data);
  return response;
}

async function deleteCoupon(promoCode) {
  const data = { promoCode };
  const response = await apiRequest(
    "POST",
    `api/admin/delete-promo-code`,
    data,
  );
  return response;
}

async function deleteUser(email) {
  const data = { email };
  const response = await apiRequest("POST", `api/admin/delete-user-db`, data);
  return response;
}

async function deletePharmacy(id) {
  const data = { id };
  const response = await apiRequest("POST", `api/storefront/delete-pharmacy`, data);
  return response;
}

async function deleteMedicine(id) {
  const data = { id };
  const response = await apiRequest("POST", `api/admin/delete-medicine`, data);
  return response;
}

async function deleteDrug(id) {
  const data = { id };
  const response = await apiRequest("POST", `api/storefront/delete-drug`, data);
  return response;
}

async function makeAdmin(email, privileges) {
  const data = { email, privileges };
  const response = await apiRequest("POST", `api/admin/make-admin`, data);
  return response;
}

async function activatePharmacy(id, storeName) {
  const data = { id, storeName };
  const response = await apiRequest("POST", `api/storefront/activate-pharmacy`, data);
  return response;
}

async function revokeAdmin(email) {
  const data = { email };
  const response = await apiRequest("POST", `api/admin/revoke-access`, data);
  return response;
}

async function disactivatePharmacy(id) {
  const data = { id };
  const response = await apiRequest("POST", `api/storefront/disactivate-pharmacy`, data);
  return response;
}

async function registerAdmin(email, password, firstName, lastName) {
  const data = { email, password, firstName, lastName };
  const response = await apiRequest("POST", `api/users/register-admin`, data);
  return response;
}

async function removeAdmin(email) {
  const data = { email };
  const response = await apiRequest("POST", `api/users/remove-admin`, data);
  return response;
}

async function validateInsurance(id) {
  const data = { id };
  const response = await apiRequest(
    "POST",
    `api/admin/validate-insurance`,
    data,
  );
  return response;
}

async function validateAccount(email) {
  const data = { email: email, app: "https://www.nkomor.com/" };
  const response = await apiRequest(
    "POST",
    `api/admin/resend-account-link`,
    data,
  );
  return response;
}

async function updateMedicine(medicineName, amount, image, id) {
  const data = { medicineName, amount, image, id };
  const response = await apiRequest(
    "POST",
    `api/admin/update-medicine`,
    data,
  );
  return response;
}

async function getDrugImages() {
  const response = await apiRequest("GET", `api/admin/get_uploaded_images/`);
  return response;
}

export {
  fetchRefillData,
  fetchSubscribersData,
  fetchUsersData,
  fetchPharmacies,
  fetchDrugs,
  fetchInsuranceData,
  fetchLabServiceData,
  fetchPaystackData,
  fetchLoginData,
  fetchAdminLoginData,
  fetchCouponData,
  fetchContactData,
  userLogin,
  storeCoupon,
  deleteCoupon,
  makeAdmin,
  activatePharmacy,
  validateInsurance,
  validateAccount,
  revokeAdmin,
  disactivatePharmacy,
  MedicineStore,
  MedicineStoreOrders,
  deleteMedicine,
  deleteDrug,
  storeMedicine,
  fetchAdminData,
  registerAdmin,
  removeAdmin,
  deleteUser,
  deletePharmacy,
  getDrugImages,
  updateMedicine
};
