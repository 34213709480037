import React, { useState } from "react";
import { Button } from "react-bootstrap";
import "./index.css";
import { userLogin } from "../../pages/apiRoute";
import { useStateContext } from "../../contexts/ContextProvider";
import { FaSpinner } from "react-icons/fa"; // Importing a spinner icon from react-icons library

const LoginModal = () => {
  const { ActivateApp, isActive, setMyUserPriviledges } = useStateContext();
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [loading, setLoading] = useState(false); // Add loading state

  const handleLogin = async () => {
    setLoading(true); // Set loading to true before making the request
    try {
      const response = await userLogin(email, password);

      if (response) {
        if (response.data.status === "accept") {
          ActivateApp(true);

          const priviData = JSON.parse(response.data.user.privileges);
          setMyUserPriviledges(priviData);
        } else {
          ActivateApp(false);
        }
      }
    } catch (error) {
      console.log(error);
      alert(error.response.data.message);
    } finally {
      setLoading(false);
    }
  };

  return (
    <div>
      <h3 className="font-bold text-center py-1">Nkomor Admin</h3>
      <div className="flex gap-20 items-center justify-center h-screen bg-[#E6FFFD] dark:bg-main-dark-bg">
        <h1 className="tracking-wider">
          <span className="font-bold">Nkomor</span>
          <br />
          The All-in-one <br /> Healthcare Services <br />
          Platform
        </h1>
        <form className="bg-[#EDFFFE] shadow-2xl px-16 pt-10 pb-11">
          <h4 className="font-bold text-center mb-4 text-3xl">Welcome Back!</h4>
          <div className="mb-3">
            <input
              type="email"
              className="form-control"
              id="email"
              placeholder="Enter email"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
            />
          </div>
          <div className="mb-3">
            <input
              type="password"
              className="form-control"
              id="password"
              placeholder="Enter password"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
            />
          </div>
          {loading && <FaSpinner className="spinner" />}
          <Button
            variant="info"
            onClick={handleLogin}
            style={{ float: "right" }}
          >
            Sign in
          </Button>
        </form>
      </div>
    </div>
  );
};

export default LoginModal;
