import React, { useState, useEffect } from "react";
import { Table, Button, Form, Spinner } from "react-bootstrap"; // Import Form component
import BootstrapTable from "react-bootstrap-table-next";
import paginationFactory from "react-bootstrap-table2-paginator";
import "bootstrap/dist/css/bootstrap.min.css";
import { fetchInsuranceData, validateInsurance } from "./apiRoute";
import { Header } from "../components";
import { useStateContext } from "../contexts/ContextProvider";
import { Confirm, Info } from "../components/Alert.js";
import { BsCheckLg } from "react-icons/bs";

const Insurance = () => {
  const { isActive } = useStateContext();
  const [refillData, setRefillData] = useState([]);
  const [searchTerm, setSearchTerm] = useState(""); // State for search term
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    const fetchData = async () => {
      try {
        setLoading(true);
        const response = await fetchInsuranceData();
        setRefillData(response.data.data.user);
        console.log(response);
      } catch (error) {
        console.error("Error fetching data:", error);
      } finally {
        setLoading(false);
      }
    };
    fetchData();
  }, []);

  const formatDate = (timestamp) => {
    if (timestamp <= 0) {
      return "";
    }

    const date = new Date(timestamp);
    const day = date.getDate();
    const month = date.toLocaleString("default", { month: "long" });
    const year = date.getFullYear();
    return `${day}-${month}-${year}`;
  };

  const handleValidation = async (value) => {
    const confirmResult = await Confirm(
      "Confirm Operation",
      `Do you want to proceed with this operation`
    );
    if (confirmResult.isConfirmed) {
      try {
        setLoading(true);
        const response = await validateInsurance(value);
        const responseAgain = await fetchInsuranceData();
        setRefillData(responseAgain.data.data.user);
        Info("Success", response.data.message);
      } catch (error) {
        console.log(error);
      } finally {
        setLoading(false);
      }
    }
  };

  const formatterColor = (cell, row) => {
    let status = "";
    let backgroundColor = "";

    if (row.delivery_status === "Completed") {
      status = "Completed";
      backgroundColor = "green";
    } else {
      status = "Pending";
      backgroundColor = "red";
    }

    return (
      <div
        style={{
          backgroundColor: backgroundColor,
          color: "white",
          padding: "5px",
          borderRadius: "5px",
          textAlign: "center",
        }}
      >
        {status}
      </div>
    );
  };

  const columns = [
    {
      dataField: "count",
      text: "#",
      formatter: (cell, row, rowIndex) => rowIndex + 1,
      headerStyle: { width: "50px", backgroundColor: "#99E6E3" },
    },
    {
      dataField: "email",
      text: "Email",
      sort: true,
      headerStyle: {
        backgroundColor: "#99E6E3",
      },
    },
    {
      dataField: "package",
      text: "Package",
      sort: true,
      headerStyle: {
        backgroundColor: "#99E6E3",
      },
    },
    {
      dataField: "delivery_status",
      text: "Status",
      sort: true,
      formatter: formatterColor,
      headerStyle: {
        backgroundColor: "#99E6E3",
      },
    },
    {
      dataField: "time_in",
      text: "Time In",
      sort: true,
      formatter: (cell, row) => formatDate(cell),
      headerStyle: {
        backgroundColor: "#99E6E3",
      },
    },
    {
      dataField: "make_admin",
      text: "Validate Insurance",
      headerStyle: {
        backgroundColor: "#99E6E3",
      },
      formatter: (cell, row) => (
        <>
          {row.delivery_status !== "Completed" && (
            <div className="flex justify-center items-center">
              <Button
                variant="danger"
                title="Validate"
                onClick={() => handleValidation(row.id)}
              >
                <BsCheckLg />
              </Button>
            </div>
          )}
        </>
      ),
    },
  ];

  const totalOrders = refillData.length;
  const processedOrders = refillData.filter(
    (order) =>
      order.delivery_status === "Received" ||
      order.delivery_status === "Completed" ||
      order.delivery_status === "Dispatched"
  ).length;
  const pendingOrders = refillData.filter(
    (order) => order.delivery_status === "Pending"
  ).length;

  // Filter data based on search term
  const filteredData = refillData.filter(
    (order) =>
      order.email.toLowerCase().includes(searchTerm.toLowerCase()) ||
      order.package.toLowerCase().includes(searchTerm.toLowerCase()) ||
      order.delivery_status.toLowerCase().includes(searchTerm.toLowerCase())
  );

  return (
    <>
      {isActive === true ? (
        <div className="m-2 md:m-10 mt-24 p-4 md:p-10 bg-[#E6FFFD] rounded-3xl ">
          <Header title="Insurance Orders" />
          <div className="flex justify-around gap-10 mb-8">
            <div className="w-1/3 p-4 bg-white rounded-lg shadow-md font-semibold">
              Total Inquiries <br />
              <span className="font-extrabold text-xl">{totalOrders}</span>
            </div>
            <div className="w-1/3 p-4 bg-white rounded-lg shadow-md font-semibold">
              Total Orders Processed <br />
              <span className="font-extrabold text-xl">{processedOrders}</span>
            </div>
            <div className="w-1/3 p-4 bg-white rounded-lg shadow-md font-semibold">
              Total Orders Pending <br />
              <span className="font-extrabold text-xl">{pendingOrders}</span>
            </div>
          </div>
          {/* Search input */}
          <Form.Control
            type="text"
            placeholder="Search for Order..."
            className="mb-3"
            value={searchTerm}
            style={{ width: "300px", float: "right" }}
            onChange={(e) => setSearchTerm(e.target.value)}
          />
          <div>
            {loading ? (
              <Spinner animation="border" size="sm" />
            ) : (
              <BootstrapTable
                keyField="id"
                data={filteredData} // Use filteredData instead of refillData
                columns={columns}
                bordered={false}
                pagination={paginationFactory()}
              />
            )}
          </div>
        </div>
      ) : (
        ""
      )}
    </>
  );
};

export default Insurance;
