import React, { useState, useEffect } from "react";
import { Table, Button, Form, Modal, Spinner } from "react-bootstrap"; // Import Form component
import BootstrapTable from "react-bootstrap-table-next";
import paginationFactory from "react-bootstrap-table2-paginator";
import "bootstrap/dist/css/bootstrap.min.css";
import {
  fetchAdminData,
  makeAdmin,
  removeAdmin,
  revokeAdmin,
  registerAdmin,
} from "./apiRoute";
import { Header } from "../components";
import { FaLink, FaSpinner, FaUserAlt, FaUserPlus } from "react-icons/fa";
import { useStateContext } from "../contexts/ContextProvider";
import { Confirm, Info } from "../components/Alert.js";
import { BsBoxArrowUpRight, BsX } from "react-icons/bs";

const Admin = () => {
  const { isActive } = useStateContext();
  const [refillData, setRefillData] = useState([]);
  const [searchTerm, setSearchTerm] = useState(""); // State for search term
  const [waiting, setWaiting] = useState(false);
  const [adminEmail, setAdminEmail] = useState("");
  const [loading, setLoading] = useState(false);
  const [privileges, setPrivileges] = useState({
    users: false,
    subscribers: false,
    coupon: false,
    export: false,
    "prescription-orders": false,
    "insurance-orders": false,
    "lab-services": false,
    "paystack-api": false,
    "medicine-store": false,
    "file-manager": false,
    "app-messages": false,
    "log-out": false,
    "login-history": false,
    "pharmacies": false,
    "drugs": false,
    dashboard: false,
    can_view: false,
    can_edit: false,
    can_delete: false,
  });

  useEffect(() => {
    const fetchData = async () => {
      try {
        setLoading(true);
        const response = await fetchAdminData();
        setRefillData(response.data.data.user);
      } catch (error) {
        console.error("Error fetching data:", error);
      } finally {
        setLoading(false);
      }
    };
    fetchData();
  }, []);

  const [showModal, setShowModal] = useState(false);
  const [selectedUser, setSelectedUser] = useState(null);

  const handleOnboard = () => {
    setShowModal(true);
  };

  const handleClose = () => {
    setShowModal(false);
    setSelectedUser(null);
  };

  const handleAdmins = async (email) => {
    try {
      const confirmResult = await Confirm(
        "Confirm Update Credentials",
        `Do you want to proceed with this operation`
      );
      if (confirmResult.isConfirmed) {
        setLoading(true);
        const response = await makeAdmin(email, JSON.stringify(privileges));
        const responseAgain = await fetchAdminData();
        setRefillData(responseAgain.data.data.user);
        Info("Success", response.data.message);
        setShowModal(false);
        setShowUsageModal(false);
      }
    } catch (error) {
      console.log(error);
      Info("Error", error.response.data.error);
    } finally {
      setLoading(false);
    }
  };

  const handleAdmin = async (email, privileges) => {
    const confirmResult = await Confirm(
      "Confirm Operation",
      `Please tick the checkbox that will show on the next page for each modules in the admin dashboard. Do you want to proceed with this operation`
    );
    if (confirmResult.isConfirmed) {
      try {
        setShowUsageModal(true);

        setAdminEmail(email);
        setPrivileges(privileges);
      } catch (error) {
        console.log(error);
        Info("Error", error.response.data.error);
      }
    }
  };

  const [showUsageModal, setShowUsageModal] = useState(false);

  const handleUsageModal = (user) => {
    setShowUsageModal(true);
  };

  const handleUsageClose = () => {
    setShowUsageModal(false);
  };

  const handleAdminRevoke = async (email) => {
    const confirmResult = await Confirm(
      "Confirm Revoke",
      `Do you want to proceed with this operation`
    );
    if (confirmResult.isConfirmed) {
      try {
        setLoading(true);
        const response = await revokeAdmin(email);
        Info("Success", response.data.message);
      } catch (error) {
        console.log(error);
        Info("Error", error.response.data.error);
      } finally {
        setLoading(false);
      }
    }
  };

  const formatDate = (timestamp) => {
    if (timestamp <= 0) {
      return "";
    }

    const date = new Date(timestamp);
    const day = date.getDate();
    const month = date.toLocaleString("default", { month: "long" });
    const year = date.getFullYear();
    return `${day}-${month}-${year}`;
  };

  const formatterColor = (cell, row) => {
    let status = "";
    let backgroundColor = "";

    if (cell === 2) {
      status = "Complete";
      backgroundColor = "green";
    } else {
      status = "Incomplete";
      backgroundColor = "red";
    }

    return (
      <div
        style={{
          backgroundColor: backgroundColor,
          color: "white",
          padding: "5px",
          borderRadius: "5px",
          textAlign: "center",
        }}
      >
        {status}
      </div>
    );
  };

  const formatterAdmin = (cell, row) => {
    let status = "";
    let backgroundColor = "";

    if (cell === 1) {
      status = "Yes";
      backgroundColor = "gray";
    } else {
      status = "No";
      backgroundColor = "red";
    }

    return (
      <div
        style={{
          backgroundColor: backgroundColor,
          color: "white",
          padding: "5px",
          borderRadius: "5px",
          textAlign: "center",
        }}
      >
        {status}
      </div>
    );
  };

  const columns = [
    {
      dataField: "count",
      text: "#",
      formatter: (cell, row, rowIndex) => rowIndex + 1,
      headerStyle: { width: "50px", backgroundColor: "#99E6E3" },
    },
    {
      dataField: "timein",
      text: "Reg Date",
      formatter: (cell, row) => formatDate(cell),
      headerStyle: {
        backgroundColor: "#99E6E3",
      },
    },
    {
      dataField: "email",
      text: "Email",
      sort: true,
      headerStyle: {
        backgroundColor: "#99E6E3",
      },
    },
    {
      dataField: "reg_complete",
      text: "Reg Status",
      sort: true,
      formatter: formatterColor,
      headerStyle: {
        backgroundColor: "#99E6E3",
      },
    },
    {
      dataField: "firstname",
      text: "Full Name",
      sort: true,
      headerStyle: {
        backgroundColor: "#99E6E3",
      },
      formatter: (cell, row) => (
        <>
          {row.firstname} {row.lastname}
        </>
      ),
    },
    {
      dataField: "can_admin",
      text: "Admin",
      sort: true,
      formatter: formatterAdmin,
      headerStyle: {
        backgroundColor: "#99E6E3",
      },
    },

    {
      dataField: "make_admin",
      text: "Credentials",
      headerStyle: {
        backgroundColor: "#99E6E3",
      },
      formatter: (cell, row) => (
        <>
          <div className="flex justify-center">
            <Button
              variant="danger"
              title="Credentials"
              onClick={() => handleAdmin(row.email, JSON.parse(row.privileges))}
            >
              <BsBoxArrowUpRight />
            </Button>
          </div>
        </>
      ),
    },
    {
      dataField: "id",
      text: "Disable Admin",
      headerStyle: {
        backgroundColor: "#99E6E3",
      },
      formatter: (cell, row) => (
        <>
          <div className="flex justify-center">
            <Button
              variant="danger"
              title="Disable Admin"
              onClick={() => handleResend(row.email)}
            >
              <BsX size={20} />
            </Button>
          </div>
        </>
      ),
    },
  ];

  const handleResend = async (email) => {
    const confirmResult = await Confirm(
      "Confirm Operation",
      `Do you want to remove this user as an admin?`
    );
    if (confirmResult.isConfirmed) {
      try {
        setLoading(true);
        const response = await removeAdmin(email);

        if (response && response.data.status === 206) {
          Info("Success", response.data.message);
        }
        const responseAgain = await fetchAdminData();
        setRefillData(responseAgain.data.data.user);
        console.log(response);
      } catch (error) {
        console.log(error);
      } finally {
        setLoading(false);
      }
    }
  };

  // Filter data based on search term
  const filteredData = refillData.filter(
    (user) =>
      user.email.toLowerCase().includes(searchTerm.toLowerCase()) ||
      user.firstname.toLowerCase().includes(searchTerm.toLowerCase()) ||
      user.lastname.toLowerCase().includes(searchTerm.toLowerCase()) ||
      user.address.toLowerCase().includes(searchTerm.toLowerCase()) ||
      user.phone.toLowerCase().includes(searchTerm.toLowerCase()) ||
      user.town_name.toLowerCase().includes(searchTerm.toLowerCase()) ||
      user.postalcode.toLowerCase().includes(searchTerm.toLowerCase()) ||
      user.gender.toLowerCase().includes(searchTerm.toLowerCase())
  );

  const handleCheckboxChange = (e) => {
    const { name, checked } = e.target;
    setPrivileges((prevState) => ({
      ...prevState,
      [name]: checked,
    }));
  };

  const handleSave = () => {
    // Save the privileges object as needed
    console.log("User Privileges:", privileges);
    setWaiting(true);
    handleUsageClose();
    handleAdmins(adminEmail);
  };

  const [formData, setFormData] = useState({
    firstName: "",
    lastName: "",
    email: "",
    password: "",
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleStore = async (e) => {
    e.preventDefault();
    const confirmResult = await Confirm(
      "Confirm Revoke",
      `Do you want to proceed with this operation`
    );
    if (confirmResult.isConfirmed) {
      try {
        setLoading(true);
        const response = await registerAdmin(
          formData.email,
          formData.password,
          formData.firstName,
          formData.lastName
        );

        if (response.data.status === "accept") {
          setFormData({
            firstName: "",
            lastName: "",
            email: "",
            password: "",
          });
          Info("Success", response.data.message);
          const responseAgain = await fetchAdminData();
          setRefillData(responseAgain.data.data.user);
          handleClose();
        } else {
          Info("Error", "Registration cannot be completed at the moment");
        }
      } catch (error) {
        console.log(error);
        Info("Error", error.response.data.error);
      } finally {
        setLoading(false);
      }
    }
  };

  return (
    <>
      {isActive === true ? (
        <div className="m-2 md:m-10 mt-24 p-4 md:p-10 bg-[#E6FFFD] rounded-3xl">
          <Header category="Nkomor" title="Admin" />
          <Form.Control
            type="text"
            placeholder="Search for Order..."
            className="mb-3"
            value={searchTerm}
            style={{ width: "300px", float: "right" }}
            onChange={(e) => setSearchTerm(e.target.value)}
          />
          <div style={{ marginBottom: "5px" }}>
            <Button
              variant="info"
              title="Profile"
              onClick={() => handleOnboard()}
              style={{ color: "white" }}
            >
              Onboard Admin
            </Button>
          </div>
          <div style={{ cursor: "pointer" }}>
            {loading ? (
              <Spinner animation="border" size="sm" />
            ) : (
              <BootstrapTable
                keyField="email"
                data={filteredData} // Use filteredData instead of refillData
                columns={columns}
                bordered={false}
                pagination={paginationFactory()}
              />
            )}
          </div>
        </div>
      ) : (
        <div>User not logged in</div>
      )}

      <Modal show={showModal} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>Admin Profile</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form onSubmit={handleStore}>
            <Form.Group controlId="formFirstName">
              <Form.Label>First Name</Form.Label>
              <Form.Control
                type="text"
                name="firstName"
                value={formData.firstName}
                onChange={handleChange}
                placeholder="Enter your first name"
              />
            </Form.Group>

            <Form.Group controlId="formLastName">
              <Form.Label>Last Name</Form.Label>
              <Form.Control
                type="text"
                name="lastName"
                value={formData.lastName}
                onChange={handleChange}
                placeholder="Enter your last name"
              />
            </Form.Group>

            <Form.Group controlId="formEmail">
              <Form.Label>Email address</Form.Label>
              <Form.Control
                type="email"
                name="email"
                value={formData.email}
                onChange={handleChange}
                placeholder="Enter email"
              />
            </Form.Group>

            <Form.Group controlId="formPassword">
              <Form.Label>Password</Form.Label>
              <Form.Control
                type="password"
                name="password"
                value={formData.password}
                onChange={handleChange}
                placeholder="Password"
              />
            </Form.Group>

            <Button variant="primary" type="submit" className="mt-5">
              Submit
            </Button>
          </Form>
        </Modal.Body>
      </Modal>

      <Modal show={showUsageModal} onHide={handleUsageClose}>
        <Modal.Header closeButton>
          <Modal.Title>User Privileges</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form>
            {Object.keys(privileges).map((module) => (
              <Form.Group
                controlId={`formBasicCheckbox-${module}`}
                key={module}
              >
                <Form.Check
                  type="checkbox"
                  label={module.toUpperCase()}
                  name={module}
                  checked={privileges[module]}
                  onChange={handleCheckboxChange}
                />
              </Form.Group>
            ))}
          </Form>
        </Modal.Body>
        <Modal.Footer>
          {loading ? (
            <Spinner animation="border" size="sm" />
          ) : (
            <>
              <Button variant="secondary" onClick={handleUsageClose}>
                Close
              </Button>
              <Button variant="primary" onClick={handleSave}>
                Save Changes
              </Button>
            </>
          )}
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default Admin;
