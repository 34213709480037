import React, { useEffect, useState } from "react";
import { AiOutlineMenu } from "react-icons/ai";
import { TooltipComponent } from "@syncfusion/ej2-react-popups";
import "bootstrap/dist/css/bootstrap.min.css";

import { LoginModal } from ".";
import { useStateContext } from "../contexts/ContextProvider";

const NavButton = ({ title, customFunc, icon, color, dotColor }) => (
  <TooltipComponent content={title} position="BottomCenter">
    <button
      type="button"
      onClick={customFunc}
      style={{ color }}
      className="relative text-xl rounded-full p-3
    hover:bg-light-gray"
    >
      <span
        style={{ background: dotColor }}
        className="absolute inline-flex rounded-full h-2 w-2 right-2 top-2"
      />
      {icon}
    </button>
  </TooltipComponent>
);
const Navbar = () => {
  const [showLoginModal, setShowLoginModal] = useState(false);
  const {
    activeMenu,
    setActiveMenu,
    isClicked,
    setIsClicked,
    handleClick,
    screenSize,
    setScreenSize,
    currentColor,
    isActive,
  } = useStateContext();

  useEffect(() => {
    const handleResize = () => setScreenSize(window.innerWidth);

    window.addEventListener("resize", handleResize);

    handleResize();

    return () => window.removeEventListener("resize", handleResize);
  }, []);

  useEffect(() => {
    if (screenSize <= 900) {
      setActiveMenu(false);
    } else {
      setActiveMenu(true);
    }
  }, [screenSize]);

  useEffect(() => {
    if (isActive === false) {
      setShowLoginModal(true);
    } else {
      setShowLoginModal(false);
    }
  });

  return (
    <>
      {isActive === true ? (
        <div
          className={`flex justify-between p-2 relative ${
            activeMenu === false ? "md:mx-6" : "md:ml-72 "
          } `}
        >
          <NavButton
            title="Menu"
            customFunc={() =>
              setActiveMenu((prevActiveMenu) => !prevActiveMenu)
            }
            color={currentColor}
            icon={<AiOutlineMenu />}
          />
        </div>
      ) : (
        <LoginModal />
      )}
    </>
  );
};

export default Navbar;
