import React, { useState, useEffect } from "react";
import { Table, Form, Button, Spinner } from "react-bootstrap";
import BootstrapTable from "react-bootstrap-table-next";
import paginationFactory from "react-bootstrap-table2-paginator";
import "bootstrap/dist/css/bootstrap.min.css";
import { fetchContactData } from "./apiRoute";
import { Header } from "../components";
import { useStateContext } from "../contexts/ContextProvider";

const Contact = () => {
  const { isActive } = useStateContext();
  const [allData, setAllData] = useState([]);
  const [filteredData, setFilteredData] = useState([]);
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    const fetchData = async () => {
      try {
        setLoading(true);
        const response = await fetchContactData();
        setAllData(response.data.data.user);
        setFilteredData(response.data.data.user); // Initialize filteredData with all data
      } catch (error) {
        console.error("Error fetching data:", error);
      } finally {
        setLoading(false);
      }
    };
    fetchData();
  }, []);

  useEffect(() => {
    filterDataByDateRange();
  }, [startDate, endDate]);

  const filterDataByDateRange = () => {
    if (!startDate || !endDate) {
      setFilteredData(allData); // If no date range selected, show all data
      return;
    }

    const filtered = allData.filter((item) => {
      const timein = new Date(item.timein);
      return timein >= new Date(startDate) && timein <= new Date(endDate);
    });
    setFilteredData(filtered);
  };

  const formatDate = (timestamp) => {
    if (timestamp <= 0) {
      return "";
    }

    const date = new Date(timestamp);
    const day = date.getDate();
    const month = date.toLocaleString("default", { month: "long" });
    const year = date.getFullYear();
    return `${day}-${month}-${year}`;
  };

  const columns = [
    {
      dataField: "count",
      text: "#",
      formatter: (cell, row, rowIndex) => rowIndex + 1,
      headerStyle: { width: "50px", backgroundColor: "#99E6E3" },
    },
    {
      dataField: "name",
      text: "Name",
      sort: true,
      headerStyle: {
        backgroundColor: "#99E6E3",
      },
    },
    {
      dataField: "email",
      text: "Email",
      sort: true,
      headerStyle: {
        backgroundColor: "#99E6E3",
      },
    },
    {
      dataField: "phone",
      text: "Phone",
      sort: true,
      headerStyle: {
        backgroundColor: "#99E6E3",
      },
    },
    {
      dataField: "message",
      text: "Message",
      sort: true,
      headerStyle: {
        backgroundColor: "#99E6E3",
      },
    },
    {
      dataField: "timein",
      text: "Date",
      sort: true,
      headerStyle: {
        backgroundColor: "#99E6E3",
      },
      formatter: (cell, row) => formatDate(cell),
    },
  ];

  const handleFilterButtonClick = () => {
    filterDataByDateRange(); // Trigger filtering when filter button is clicked
  };

  return (
    <>
      {isActive === true ? (
        <div className="m-2 md:m-10 mt-24 p-4 md:p-10 bg-[#E6FFFD] rounded-3xl ">
          <Header category="Contacts" title="App Messages" />
          <div>
            <Form inline>
              <div className="row">
                <div className="col-sm">
                  <Form.Group controlId="startDate" className="mr-3">
                    <Form.Label className="mr-2">Start Date</Form.Label>
                    <Form.Control
                      type="date"
                      value={startDate}
                      onChange={(e) => setStartDate(e.target.value)}
                    />
                  </Form.Group>
                </div>
                <div className="col-sm">
                  <Form.Group controlId="endDate" className="mr-3">
                    <Form.Label className="mr-2">End Date</Form.Label>
                    <Form.Control
                      type="date"
                      value={endDate}
                      onChange={(e) => setEndDate(e.target.value)}
                    />
                  </Form.Group>
                </div>
                {/* <div className="col-sm">
              <Button variant="primary" onClick={handleFilterButtonClick}>Filter</Button>
            </div> */}
              </div>
            </Form>
            <br />

            {loading ? (
              <Spinner animation="border" size="sm" />
            ) : (
              <BootstrapTable
                keyField="email"
                data={filteredData}
                columns={columns}
                bordered={false}
                pagination={paginationFactory()}
              />
            )}
          </div>
        </div>
      ) : (
        <div>User not logged in</div>
      )}
    </>
  );
};

export default Contact;
