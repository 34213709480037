import React, { useState, useEffect } from "react";
import { Table, Button, Modal, Form, Spinner } from "react-bootstrap";
import BootstrapTable from "react-bootstrap-table-next";
import paginationFactory from "react-bootstrap-table2-paginator";
import { FaTrash, FaEdit } from "react-icons/fa"; // Import FaTrash and FaEdit icons for actions
import "bootstrap/dist/css/bootstrap.min.css";
import {
  MedicineStore,
  storeMedicine,
  deleteMedicine,
  MedicineStoreOrders,
  updateMedicine,
} from "./apiRoute";
import { Header } from "../components";
import { useStateContext } from "../contexts/ContextProvider";
import { Confirm, Info } from "../components/Alert";

const MedicineStores = () => {
  const { isActive } = useStateContext();
  const [refillData, setRefillData] = useState([]);
  const [medicineOrderData, setMedicineOrderData] = useState([]);
  const [showModal, setShowModal] = useState(false);
  const [showEditModal, setShowEditModal] = useState(false);
  const [medicineName, setMedicineName] = useState("");
  const [amount, setAmount] = useState("");
  const [image, setImage] = useState("");
  const [id, setId] = useState();
  const [loading, setLoading] = useState(true);
  const [searchTerm, setSearchTerm] = useState("");

  useEffect(() => {
    const fetchData = async () => {
      try {
        const [response1, response2] = await Promise.all([
          MedicineStore(),
          MedicineStoreOrders(),
        ]);
        setLoading(false);
        setRefillData(response1.data.data.user);
        setMedicineOrderData(response2.data.data.users);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };
    fetchData();
  }, []);

  const handleStoreMedicine = async () => {
    const confirmResult = await Confirm(
      "Confirm Task",
      "Do you want to continue with this operation?"
    );
    if (confirmResult.isConfirmed) {
      try {
        setLoading(true);
        const response = await storeMedicine(medicineName, amount, image);
        const responseAgain = await MedicineStore();
        setRefillData(responseAgain.data.data.user);
        Info("Create Medicine", response.data.message);
      } catch (error) {
        console.log(error);
        Info("Error", error.response.data.error);
      } finally {
        setShowModal(false);
        setLoading(false);
        setMedicineName("");
        setAmount("");
        setImage("");
      }
    }
  };

  const handleDeleteMedicine = async (id) => {
    const confirmResult = await Confirm(
      "Delete Medicine",
      "Do you want to delete this medicine?"
    );
    if (confirmResult.isConfirmed) {
      try {
        setLoading(true);
        const response = await deleteMedicine(id);
        const responseAgain = await MedicineStore();
        setRefillData(responseAgain.data.data.user);
        Info("Delete Medicine", response.data.message);
      } catch (error) {
        console.log(error);
        Info("Error", error.response.data.error);
      } finally {
        setLoading(false);
      }
    }
  };

  const editMedicine = async (id) => {
    const confirmResult = await Confirm(
      "Edit Medicine",
      "Do you want to edit this medicine?"
    );
    if (confirmResult.isConfirmed) {
      try {
        const data = refillData.filter((medicine) => medicine.id === id);
        setMedicineName(data[0].medicine);
        setAmount(data[0].price);
        setImage(data[0].image);
        setId(data[0].id);

        setShowEditModal(true);
        setLoading(true);
      } catch (error) {
        console.log(error);
        Info("Error", error.response.data.error);
      } finally {
        setLoading(false);
      }
    }
  };

  const handleEditMedicine = async () => {
    try {
      setLoading(true);
      const response = await updateMedicine(medicineName, amount, image, id);
      const responseAgain = await MedicineStore();
      setRefillData(responseAgain.data.data.user);
      Info("Update Medicine");
    } catch (error) {
      console.log(error);
      Info("Error", error.response.data.error);
    } finally {
      setShowEditModal(false);
      setLoading(false);
      setMedicineName("");
      setAmount("");
      setImage("");
      setId("");
    }
  };

  const columns = [
    {
      dataField: "count",
      text: "#",
      formatter: (cell, row, rowIndex) => rowIndex + 1,
      headerStyle: { width: "50px", backgroundColor: "#99E6E3" },
    },
    {
      dataField: "medicine",
      text: "Medicine Name",
      sort: true,
      headerStyle: {
        backgroundColor: "#99E6E3",
      },
    },
    {
      dataField: "price",
      text: "Amount",
      sort: true,
      headerStyle: {
        backgroundColor: "#99E6E3",
      },
    },
    {
      dataField: "currency",
      text: "Currency",
      sort: true,
      headerStyle: {
        backgroundColor: "#99E6E3",
      },
    },
    {
      dataField: "image",
      text: "Image",
      sort: true,
      headerStyle: {
        backgroundColor: "#99E6E3",
      },
    },
    {
      dataField: "action",
      text: "Actions",
      headerStyle: {
        backgroundColor: "#99E6E3",
      },
      formatter: (cell, row) => (
        <div className="flex">
          <Button
            variant="danger"
            onClick={() => handleDeleteMedicine(row.id)}
            className="mr-2"
          >
            <FaTrash />
          </Button>
          <Button
            variant="success"
            onClick={() => editMedicine(row.id)}
            className="mr-2"
          >
            <FaEdit />
          </Button>
        </div>
      ),
    },
  ];

  // Medicine Revenue
  const medicineRevenue = medicineOrderData
    .filter((order) => order.Is_payment === "true")
    .reduce((total, item) => total + item.total, 0)
    .toFixed(2);

  const filteredData = refillData.filter((user) =>
    user.medicine.toLowerCase().includes(searchTerm.toLowerCase())
  );

  return (
    <>
      {isActive ? (
        <div className="m-2 md:m-10 mt-24 p-4 md:p-10 bg-[#E6FFFD] rounded-3xl ">
          <Header category="Store" title="Medicine Store" />
          <div className="flex gap-3 mb-8">
            <div className="w-1/3 p-4 bg-white rounded-lg shadow-md font-semibold">
              Medicine Store Revenue <br />
              <span className="font-extrabold text-xl">
                ₵ {medicineRevenue}
              </span>
            </div>
          </div>
          <div style={{ marginBottom: "5px" }}>
            <Button
              variant="info"
              onClick={() => setShowModal(true)}
              style={{ color: "white" }}
            >
              Create New
            </Button>
          </div>
          <Form.Control
            type="text"
            placeholder="Search for Order..."
            className="mb-3"
            value={searchTerm}
            style={{ width: "300px", float: "right" }}
            onChange={(e) => setSearchTerm(e.target.value)}
          />
          <div>
            {loading ? (
              <Spinner animation="border" size="sm" />
            ) : (
              <BootstrapTable
                keyField="id"
                data={filteredData}
                columns={columns}
                bordered={false}
                pagination={paginationFactory()}
              />
            )}
          </div>

          <Modal
            show={!showModal ? showEditModal : showModal}
            onHide={() =>
              showEditModal ? setShowEditModal(false) : setShowModal(false)
            }
          >
            <Modal.Header closeButton>
              <Modal.Title>
                {showEditModal ? "Edit Medicine" : "Create Medicine"}
              </Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <Form>
                <Form.Group controlId="medicineName">
                  <Form.Label>Medicine Name</Form.Label>
                  <Form.Control
                    type="text"
                    placeholder="Enter medicine name"
                    value={medicineName}
                    onChange={(e) => setMedicineName(e.target.value)}
                  />
                </Form.Group>
                <Form.Group controlId="amount">
                  <Form.Label>Amount</Form.Label>
                  <Form.Control
                    type="number"
                    placeholder="Enter amount"
                    value={amount}
                    onChange={(e) => setAmount(e.target.value)}
                  />
                </Form.Group>
                <Form.Group controlId="image">
                  <Form.Label>Image URL</Form.Label>
                  <Form.Control
                    type="text"
                    placeholder="Enter image URL"
                    value={image}
                    onChange={(e) => setImage(e.target.value)}
                  />
                </Form.Group>
              </Form>
            </Modal.Body>
            <Modal.Footer>
              {loading ? (
                <Spinner animation="border" size="sm" />
              ) : (
                <>
                  <Button
                    variant="secondary"
                    onClick={() =>
                      showEditModal
                        ? setShowEditModal(false)
                        : setShowModal(false)
                    }
                  >
                    Close
                  </Button>
                  <Button
                    variant="primary"
                    onClick={
                      showEditModal ? handleEditMedicine : handleStoreMedicine
                    }
                  >
                    {showEditModal ? "Update" : "Save"}
                  </Button>
                </>
              )}
            </Modal.Footer>
          </Modal>
        </div>
      ) : (
        ""
      )}
    </>
  );
};

export default MedicineStores;
