import React, { useState, useEffect } from "react";
import { Table, Form, Modal, Button, Spinner } from "react-bootstrap"; // Import Form component
import BootstrapTable from "react-bootstrap-table-next";
import paginationFactory from "react-bootstrap-table2-paginator";
import "bootstrap/dist/css/bootstrap.min.css";
import { fetchRefillData } from "./apiRoute";
import { Header } from "../components";
import { useStateContext } from "../contexts/ContextProvider";
import { FaFileExcel } from "react-icons/fa";
import { exportToCSV } from "../components/csvExport";
import { BsBoxArrowUpRight } from "react-icons/bs";

const Orders = () => {
  const { isActive } = useStateContext();
  const [refillData, setRefillData] = useState([]);
  const [searchTerm, setSearchTerm] = useState("");
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    const fetchData = async () => {
      try {
        setLoading(true);
        const response = await fetchRefillData();
        setRefillData(response.data.data.user);
      } catch (error) {
        console.error("Error fetching data:", error);
      } finally {
        setLoading(false);
      }
    };
    fetchData();
  }, []);

  const [showModal, setShowModal] = useState(false);
  const [selectedUser, setSelectedUser] = useState(null);

  const handleModal = (user) => {
    setSelectedUser(user);
    setShowModal(true);
  };

  const handleClose = () => {
    setShowModal(false);
    setSelectedUser(null);
  };

  const formatDateTime = (timestamp) => {
    const date = new Date(timestamp);
    const formattedDate = date.toLocaleDateString();
    const formattedTime = date.toLocaleTimeString();
    return `${formattedDate} ${formattedTime}`;
  };

  const formatDate = (timestamp) => {
    const date = new Date(timestamp);
    const formattedDate = date.toLocaleDateString();
    const formattedTime = date.toLocaleTimeString();
    return `${formattedDate} ${formattedTime}`;
  };

  const formatterColor = (cell, row) => {
    let status = "";
    let backgroundColor = "";

    if (row.delivery_status === "Completed") {
      status = "Completed";
      backgroundColor = "green";
    } else if (row.delivery_status === "Received") {
      status = "Received";
      backgroundColor = "blue";
    } else if (row.delivery_status === "Dispatched") {
      status = "Dispatched";
      backgroundColor = "orange";
    } else {
      status = "cancelled";
      backgroundColor = "red";
    }

    return (
      <div
        style={{
          backgroundColor: backgroundColor,
          color: "white",
          padding: "5px",
          borderRadius: "5px",
          textAlign: "center",
        }}
      >
        {status}
      </div>
    );
  };

  const formatterColor2 = (cell, row) => {
    let status = "";
    let backgroundColor = "";

    if (row.payment_status === "success") {
      status = "success";
      backgroundColor = "green";
    }

    return (
      <div
        style={{
          backgroundColor: backgroundColor,
          color: "white",
          padding: "5px",
          borderRadius: "5px",
          textAlign: "center",
        }}
      >
        {status}
      </div>
    );
  };

  const columns = [
    {
      dataField: "order_number",
      text: "Order Number",
      sort: true,
       headerStyle: {
        backgroundColor: "#99E6E3",
      },
    },
    {
      dataField: "tracking_id",
      text: "Tracking Id",
       headerStyle: {
        backgroundColor: "#99E6E3",
      },
    },
    {
      dataField: "medicine",
      text: "Medicine",
       headerStyle: {
        backgroundColor: "#99E6E3",
      },
    },
    {
      dataField: "delivery_status",
      text: "Status",
      formatter: formatterColor,
       headerStyle: {
        backgroundColor: "#99E6E3",
      },
    },
    {
      dataField: "payment_status",
      text: "Payment Status",
      formatter: formatterColor2,
       headerStyle: {
        backgroundColor: "#99E6E3",
      },
    },
    {
      dataField: "id",
      text: "View ",
       headerStyle: {
        backgroundColor: "#99E6E3",
      },
      formatter: (cell, row) => (
        <>
          <Button
            variant="danger"
            title="Profile"
            onClick={() => handleModal(row)}
          >
            <BsBoxArrowUpRight />
          </Button>
        </>
      ),
    },
  ];

  const handleExport = () => {
    exportToCSV(refillData, "gmm_data.csv");
  };

  const totalCostPrice = refillData
    .reduce((total, item) => total + item.a_price, 0)
    .toFixed(2);
  const totalSellingPrice = refillData
    .reduce((total, item) => total + item.payment, 0)
    .toFixed(2);
  const totalRevenue = (totalSellingPrice - totalCostPrice).toFixed(2);
  const totalOrders = refillData.length;
  const processedOrders = refillData.filter(
    (order) =>
      order.delivery_status === "Received" ||
      order.delivery_status === "Completed" ||
      order.delivery_status === "Dispatched"
  ).length;
  const pendingOrders = refillData.filter(
    (order) => order.delivery_status === "Pending"
  ).length;

  // Filter data based on search term
  const filteredData = refillData.filter(
    (order) =>
      order.order_number.toLowerCase().includes(searchTerm.toLowerCase()) ||
      order.tracking_id.toLowerCase().includes(searchTerm.toLowerCase()) ||
      order.medicine.toLowerCase().includes(searchTerm.toLowerCase()) ||
      order.email.toLowerCase().includes(searchTerm.toLowerCase()) ||
      order.delivery_status.toLowerCase().includes(searchTerm.toLowerCase()) ||
      order.payment_status.toLowerCase().includes(searchTerm.toLowerCase())
  );

  return (
    <>
      {isActive === true ? (
        <div className="m-2 md:m-10 mt-24 md:p-10 bg-[#E6FFFD]">
          <Header title="Get My Medicine Orders" />
          <div className="flex justify-around gap-10 mb-8">
            <div className="w-1/3 p-4 bg-white rounded-lg shadow-md font-semibold">
              Total Orders <br />
              <span className="font-extrabold text-xl">{totalOrders}</span>
            </div>
            <div className="w-1/3 p-4 bg-white rounded-lg shadow-md font-semibold">
              Total Orders Processed <br />
              <span className="font-extrabold text-xl">{processedOrders}</span>
            </div>
            <div className="w-1/3 p-4 bg-white rounded-lg shadow-md font-semibold">
              Total Pending Orders <br />
              <span className="font-extrabold text-xl">{pendingOrders}</span>
            </div>
          </div>
          <div className="flex justify-around gap-10 mb-8">
            <div className="w-1/3 p-4 bg-white rounded-lg shadow-md font-semibold">
              Total Cost Price <br />
              <span className="font-extrabold text-xl">₵ {totalCostPrice}</span>
            </div>
            <div className="w-1/3 p-4 bg-white rounded-lg shadow-md font-semibold">
              Total Selling Price <br />
              <span className="font-extrabold text-xl">
                ₵ {totalSellingPrice}
              </span>
            </div>
            <div className="w-1/3 p-4 bg-white rounded-lg shadow-md font-semibold">
              Total Revenue <br />
              <span className="font-extrabold text-xl">₵ {totalRevenue}</span>
            </div>
          </div>
          {/* Search input */}
          <Form.Control
            type="text"
            placeholder="Search for Order..."
            className="mb-3"
            value={searchTerm}
            style={{ width: "300px", float: "right" }}
            onChange={(e) => setSearchTerm(e.target.value)}
          />

          <div>
            {loading ? (
              <Spinner animation="border" size="sm" />
            ) : (
              <BootstrapTable
                keyField="order_number"
                data={filteredData} // Use filteredData instead of refillData
                columns={columns}
                bordered={ false }
                pagination={paginationFactory()}
              />
            )}
          </div>
        </div>
      ) : (
        ""
      )}

      <Modal show={showModal} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>Payment Informations</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {selectedUser && (
            <div>
              <p>
                <strong>Order Number:</strong> {selectedUser.order_number}
              </p>
              <p>
                <strong>Tracking Id:</strong> {selectedUser.tracking_id}
              </p>
              <p>
                <strong>Medicine:</strong> {selectedUser.medicine}
              </p>
              <p>
                <strong>Status:</strong> {selectedUser.delivery_status}
              </p>
              <p>
                <strong>Payment Status:</strong> {selectedUser.payment_status}
              </p>
              <p>
                <strong>Cost Price (₵):</strong> {selectedUser.a_price}
              </p>
              <p>
                <strong>Selling Price (₵):</strong> {selectedUser.payment}
              </p>
              <p>
                <strong>Amount Paid:</strong> {selectedUser.amount_payed}
              </p>
              <p>
                <strong>Profit (₵): </strong>
                {`₵ ${(selectedUser.payment - selectedUser.a_price).toFixed(
                  2
                )}`}
              </p>
              <p>
                <strong>Order Date: </strong>
                {formatDateTime(selectedUser.time_in)}
              </p>
              <p>
                <strong>Payment Time: </strong>
                {formatDateTime(selectedUser.pay_time)}
              </p>
            </div>
          )}
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            Close
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default Orders;
