import React, { useEffect, useState } from "react";
import { Header } from "../components";
import { useStateContext } from "../contexts/ContextProvider";
import {
  fetchInsuranceData,
  fetchLabServiceData,
  fetchRefillData,
  fetchUsersData,
  MedicineStoreOrders,
} from "./apiRoute";
import { Doughnut } from "react-chartjs-2";
import { Chart as ChartJS, ArcElement, Tooltip, Legend } from "chart.js";
import BootstrapTable from "react-bootstrap-table-next";
import paginationFactory from "react-bootstrap-table2-paginator";

ChartJS.register(ArcElement, Tooltip, Legend);

function Dashboard() {
  const { isActive } = useStateContext();
  const [totalUsers, setTotalUsers] = useState("");
  const [insuranceData, setInsuranceData] = useState([]);
  const [prescriptionData, setPrescriptionData] = useState([]);
  const [labServiceData, setLabServiceData] = useState([]);
  const [medicineOrderData, setMedicineOrderData] = useState([]);
  const [users, setUsers] = useState([]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const [response1, response2, response3, response4, response5] =
          await Promise.all([
            fetchUsersData(),
            fetchInsuranceData(),
            fetchRefillData(),
            fetchLabServiceData(),
            MedicineStoreOrders(),
          ]);

        setTotalUsers(response1.data.data.user.length);
        setUsers(response1.data.data.user);
        setInsuranceData(response2.data.data.user);
        setPrescriptionData(response3.data.data.user);
        setLabServiceData(response4.data.data.user);
        setMedicineOrderData(response5.data.data.users);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };
    fetchData();
  }, []);

  // Transaction history Data
  const processed_labService = labServiceData.filter(
    (lab) => lab.is_payment === "True"
  );
  const processed_prescription = prescriptionData.filter(
    (order) => order.payment_status === "success"
  );
  const processed_medicineOrders = medicineOrderData.filter(
    (order) => order.Is_payment === "true"
  );

  const formatDate = (timestamp) => {
    if (timestamp <= 0) {
      return "";
    }

    const date = new Date(timestamp);
    const day = date.getDate();
    const month = date.toLocaleString("default", { month: "long" });
    const year = date.getFullYear();
    return `${day}-${month}-${year}`;
  };

  const extractedData1 = processed_prescription.map((order) => ({
    order_time: new Date(order.pay_time).toLocaleTimeString(),
    order_date: formatDate(order.pay_time), // Use formatDate here
    amount: order.amount_payed,
    id: order.id,
    orderNumber: order.order_number,
  }));

  const extractedData2 = processed_labService.map((order) => ({
    order_time: new Date(order.payment_time).toLocaleTimeString(),
    order_date: formatDate(order.payment_time), // Use formatDate here
    amount: order.price,
    orderNumber: order.orderNumber,
  }));

  const extractedData3 = processed_medicineOrders.map((order) => ({
    order_time: new Date(order.order_time).toLocaleTimeString(),
    order_date: formatDate(order.order_time), // Use formatDate here
    amount: order.total,
    orderNumber: order.Order_number,
  }));

  const combinedData = [
    ...extractedData1,
    ...extractedData2,
    ...extractedData3,
  ];

  // Sort combinedData based on the month
  combinedData.sort((a, b) => {
    // Extract the month from the formatted date strings
    const monthA = new Date(a.order_date).getMonth();
    const monthB = new Date(b.order_date).getMonth();

    // Compare the months
    return monthB - monthA;
  });

  // Total Transaction Value
  const calculateTotalTransaction = combinedData.reduce(
    (total, orderData) => total + orderData.amount,
    0
  );

  const columns = [
    {
      dataField: "orderNumber",
      text: "Order Number",
      sort: true,
      headerStyle: {
        backgroundColor: "#99E6E3",
      },
    },
    {
      dataField: "order_date",
      text: "Order Date",
      headerStyle: {
        backgroundColor: "#99E6E3",
      },
    },
    {
      dataField: "order_time",
      text: "Order Time",
      headerStyle: {
        backgroundColor: "#99E6E3",
      },
    },
    {
      dataField: "amount",
      text: "Amount(₵)",
      headerStyle: {
        backgroundColor: "#99E6E3",
      },
    },
  ];

  // Filter users to only include those where town_name is 'Accra'
  const accraUsers = users.filter((user) => user.town_name === "Accra");

  // Filter users to only include those where town_name is 'Tema'
  const temaUsers = users.filter((user) => user.town_name === "Tema");

  // Filter users to only include those where town_name is 'Takoradi'
  const takoradiUsers = users.filter((user) => user.town_name === "Takoradi");

  // Filter users to only include those where town_name is 'East Legon'
  const EastLegonUsers = users.filter(
    (user) => user.town_name === "East Legon"
  );

  // Filter users to only include those where town_name is 'Middletown'
  const MiddletownUsers = users.filter(
    (user) => user.town_name === "Middletown"
  );

  // Get the count of 'Accra' users
  const accraCount = accraUsers.length;

  // Get the count of 'Temaa' users
  const temaCount = temaUsers.length;

  // Get the count of 'Takoradi' users
  const takoradiCount = takoradiUsers.length;

  // Get the count of 'East Legon' users
  const EastLegonCount = EastLegonUsers.length;

  // Get the count of 'Middletown' users
  const Middletown = MiddletownUsers.length;

  const data = {
    labels: ["Accra", "Tema", "Takoradi", "East Legon", "Middletown"],
    datasets: [
      {
        label: "# of Votes",
        data: [
          accraCount,
          temaCount,
          takoradiCount,
          EastLegonCount,
          Middletown,
        ],
        backgroundColor: [
          "rgba(173, 232, 244, 1)",
          "rgba(123, 202, 232, 1)",
          "rgba(74, 172, 219, 1)",
          "rgba(45, 142, 188, 1)",
          "rgba(35, 112, 158, 1)",
        ],
        borderColor: [
          "rgba(173, 232, 244, 1)",
          "rgba(123, 202, 232, 1)",
          "rgba(74, 172, 219, 1)",
          "rgba(45, 142, 188, 1)",
          "rgba(35, 112, 158, 1)",
        ],
        borderWidth: 1,
      },
    ],
  };

  const options = {
    plugins: {
      legend: {
        display: true,
        position: "right",
        labels: {
          usePointStyle: true,
        },
      },
      tooltip: {
        callbacks: {
          label: function (context) {
            let label = context.label || "";
            if (label) {
              label += ": ";
            }
            if (context.raw !== null) {
              label += new Intl.NumberFormat("en-US", {
                style: "decimal",
                minimumFractionDigits: 0,
              }).format(context.raw);
            }
            return label;
          },
        },
      },
    },
  };

  // Insurance Processed
  const processedInsurance = insuranceData.filter(
    (order) =>
      order.delivery_status === "Received" ||
      order.delivery_status === "Completed" ||
      order.delivery_status === "Dispatched"
  ).length;

  // Prescription Revenue
  const totalSellingPrice = prescriptionData
    .reduce((total, item) => total + item.payment, 0)
    .toFixed(2);

  // Lab Revenue
  const processedLab = labServiceData
    .filter((lab) => lab.is_payment === "True")
    .reduce((total, item) => total + item.price, 0)
    .toFixed(2);

  // Medicine Revenue
  const medicineRevenue = medicineOrderData
    .filter((order) => order.Is_payment === "true")
    .reduce((total, item) => total + item.total, 0)
    .toFixed(2);

  // Total Transations
  const prescriptionTransactions = prescriptionData.filter(
    (order) =>
      order.delivery_status === "Received" ||
      order.delivery_status === "Completed" ||
      order.delivery_status === "Dispatched"
  ).length;

  const labTransactions = labServiceData.filter(
    (lab) => lab.is_payment === "True"
  ).length;

  const medicineTransactions = medicineOrderData.filter(
    (order) => order.Is_payment === "true"
  ).length;

  const totalTransactions =
    prescriptionTransactions +
    processedInsurance +
    labTransactions +
    medicineTransactions;

  return (
    <>
      {isActive === true ? (
        <div className="m-2 md:m-10 mt-24 md:p-10 bg-[#E6FFFD]">
          <Header title="Dashboard" />
          <div className="flex justify-around gap-3 mb-8">
            <div className="w-1/3 p-4 bg-white rounded-lg shadow-md font-semibold">
              Total Users <br />
              <span className="font-extrabold text-xl">{totalUsers}</span>
            </div>
            <div className="w-1/3 p-4 bg-white rounded-lg shadow-md font-semibold">
              Total Revenue <br />
              <span className="font-extrabold text-xl">
                ₵ {totalSellingPrice}
              </span>
            </div>
            <div className="w-1/3 p-4 bg-white rounded-lg shadow-md font-semibold">
              Total Transactions Value <br />
              <span className="font-extrabold text-xl">
                ₵ {calculateTotalTransaction.toFixed(2)}
              </span>
            </div>
            <div className="w-1/3 p-4 bg-white rounded-lg shadow-md font-semibold">
              Number of Transactions <br />
              <span className="font-extrabold text-xl">
                {totalTransactions}
              </span>
            </div>
          </div>
          <div className="flex justify-around gap-3 mb-8">
            <div className="w-1/3 p-4 bg-white rounded-lg shadow-md font-semibold">
              Insurance Processed <br />
              <span className="font-extrabold text-xl">
                {processedInsurance}
              </span>
            </div>
            <div className="w-1/3 p-4 bg-white rounded-lg shadow-md font-semibold">
              Lab Revenue <br />
              <span className="font-extrabold text-xl">₵ {processedLab}</span>
            </div>
            <div className="w-1/3 p-4 bg-white rounded-lg shadow-md font-semibold">
              Prescription Revenue <br />
              <span className="font-extrabold text-xl">
                ₵ {totalSellingPrice}
              </span>
            </div>
            <div className="w-1/3 p-4 bg-white rounded-lg shadow-md font-semibold">
              Medicine Store Revenue <br />
              <span className="font-extrabold text-xl">
                ₵ {medicineRevenue}
              </span>
            </div>
          </div>
          <h4 className="text-2xl font-extrabold mb-10">Transaction History</h4>
          <BootstrapTable
            keyField="order_number"
            data={combinedData} // Use filteredData instead of refillData
            columns={columns}
            bordered={false}
            pagination={paginationFactory()}
          />
          <div style={{ width: "900px", height: "300px" }}>
            <Doughnut data={data} options={options} />
          </div>
        </div>
      ) : (
        ""
      )}
    </>
  );
}

export default Dashboard;
