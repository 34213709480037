import React, { useState, useEffect, useRef } from "react";
import axios from "axios";
import { Spinner, Button, Card, Row, Col } from "react-bootstrap";
import { getDrugImages } from "./apiRoute";
import { Confirm, Info } from "../components/Alert.js";
import { useStateContext } from "../contexts/ContextProvider";

const FileManager = () => {
  const { isActive, userPreviledges } = useStateContext();
  const [images, setImages] = useState([]);
  const [loading, setLoading] = useState(false);
  const fileInputRef = useRef(null);

  useEffect(() => {
    fetchImages();
  }, []);

  const fetchImages = async () => {
    try {
      const response = await getDrugImages();
      setImages(response.data);
    } catch (error) {
      console.error("Error fetching images:", error);
    }
  };

  const handleUpload = async () => {
    const formData = new FormData();
    formData.append("image", fileInputRef.current.files[0]);

    try {
      const confirmResult = await Confirm(
        "Confirm Upload",
        `Do want to continue with this operation?`
      );
      if (confirmResult.isConfirmed) {
        setLoading(true);
        const response = await axios.post(
          "https://service.nkomor.com/api/admin/upload_lab_images",
          formData,
          {
            headers: {
              "Content-Type": "multipart/form-data",
            },
          }
        );
        if (response.status === 200) {
          fetchImages();
          Info("Success", "Image uploaded successfully.");
        } else {
          Info("Error", "Failed to upload image.");
        }
      }
    } catch (error) {
      console.error("Error uploading image:", error);
    } finally {
      setLoading(false);
    }
  };

  const copyToClipboard = (text) => {
    navigator.clipboard
      .writeText(text)
      .then(() => {
        Info("Success", "URL copied to clipboard");
      })
      .catch((error) => {
        console.error("Error copying to clipboard:", error);
      });
  };

  const deleteImage = async (imageName) => {
    try {
      const confirmResult = await Confirm(
        "Confirm Delete",
        `Do want to continue with this operation?`
      );
      if (confirmResult.isConfirmed) {
        setLoading(true);
        const response = await axios.delete(
          `https://service.nkomor.com/api/admin/delete_image/${imageName}`
        );
        if (response.status === 200) {
          fetchImages();
          Info("Success", "Image deleted successfully.");
        } else {
          Info("Error", "Failed to delete image.");
        }
      }
    } catch (error) {
      console.error("Error deleting image:", error);
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className="bg-[#E6FFFD]" style={{ padding: "20px" }}>
      <h2 className="font-bold mb-5">File Manager</h2>
      <input type="file" ref={fileInputRef} />
      <br />
      <p style={{ marginTop: "20px" }}>
        {loading ? (
          <Spinner animation="border" size="sm" />
        ) : (
          <Button
            variant="info"
            style={{ color: "white" }}
            onClick={handleUpload}
          >
            Upload
          </Button>
        )}
      </p>
      <Row>
        {images.map((image, index) => (
          <Col
            key={index}
            xs={12}
            sm={6}
            md={4}
            lg={3}
            style={{ marginBottom: "20px" }}
          >
            <Card style={{ width: "100%", height: "100%" }}>
              <Card.Img
                variant="top"
                src={image.url}
                style={{ width: "100%", height: "200px" }}
                alt={`Image ${index}`}
              />
              <Card.Body>
                <Button
                  variant="info"
                  onClick={() => copyToClipboard(image.url)}
                  style={{ width: "100%", color: "white" }}
                >
                  Copy URL
                </Button>
                {loading ? (
                  <Spinner animation="border" size="sm" />
                ) : (
                  <>
                    {userPreviledges.can_delete === true ? (
                      <Button
                        variant="danger"
                        onClick={() => deleteImage(image.name)}
                        style={{ width: "100%", marginTop: "10px" }}
                      >
                        Delete Image
                      </Button>
                    ) : (
                      ""
                    )}
                  </>
                )}
              </Card.Body>
            </Card>
          </Col>
        ))}
      </Row>
    </div>
  );
};

export default FileManager;
