import React, { useState, useEffect } from "react";
import { Table, Spinner } from "react-bootstrap";
import BootstrapTable from "react-bootstrap-table-next";
import paginationFactory from "react-bootstrap-table2-paginator";
import "bootstrap/dist/css/bootstrap.min.css";
import { Header } from "../components";
import { useStateContext } from "../contexts/ContextProvider";
import { fetchLoginData, fetchAdminLoginData } from "./apiRoute";

const LoginHistory = () => {
  const { isActive } = useStateContext();
  const [loginData, setLoginData] = useState([]);
  const [adminLoginData, setadminLoginData] = useState([]);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    const fetchData = async () => {
      try {
        setLoading(true);
        const [response1, response2] = await Promise.all([
          fetchLoginData(),
          fetchAdminLoginData(),
        ]);
        console.log("response1:", response1);
        console.log("response2:", response2);
        setLoginData(response1.data);
        setadminLoginData(response2.data);
      } catch (error) {
        console.error("Error fetching data:", error);
      } finally {
        setLoading(false);
      }
    };
    fetchData();
  }, []);

  const formatDate = (timestamp) => {
    if (timestamp <= 0) {
      return "";
    }

    const date = new Date(timestamp);
    const day = date.getDate();
    const month = date.toLocaleString("default", { month: "long" });
    const year = date.getFullYear();
    return `${day}-${month}-${year}`;
  };

  const formatTime = (timestamp) => {
    if (timestamp <= 0) {
      return "";
    }

    const time = new Date(timestamp).toLocaleTimeString();
    return time;
  };

  const columns = [
    {
      dataField: "count",
      text: "#",
      formatter: (cell, row, rowIndex) => rowIndex + 1,
      headerStyle: { width: "50px", backgroundColor: "#99E6E3" },
    },
    {
      dataField: "userName",
      text: "User",
      headerStyle: {
        backgroundColor: "#99E6E3",
      },
    },
    {
      dataField: "email",
      text: "Email",
      headerStyle: {
        backgroundColor: "#99E6E3",
      },
    },
    {
      dataField: "loginTime",
      text: "Login Date",
      sort: true,
      formatter: (cell, row) => formatDate(cell),
      headerStyle: {
        backgroundColor: "#99E6E3",
      },
    },
    {
      dataField: "loginTime",
      text: "Login Time",
      sort: true,
      formatter: (cell, row) => formatTime(cell),
      headerStyle: {
        backgroundColor: "#99E6E3",
      },
    },
  ];

  return (
    <>
      {isActive === true ? (
        <div className="m-2 md:m-10 mt-24 p-4 md:p-10 bg-[#E6FFFD] rounded-3xl ">
          <Header category="API" title="Admin Login History" />
          <div>
            {loading ? (
              <Spinner animation="border" size="sm" />
            ) : (
              <BootstrapTable
                keyField="public_key"
                data={adminLoginData.sort((a, b) => b.id - a.id)}
                columns={columns}
                bordered={false}
                pagination={paginationFactory()}
              />
            )}
          </div>
          <Header category="API" title="Portal Login History" />
          <div>
            {loading ? (
              <Spinner animation="border" size="sm" />
            ) : (
              <BootstrapTable
                keyField="public_key"
                data={loginData.sort((a, b) => b.id - a.id)}
                columns={columns}
                bordered={false}
                pagination={paginationFactory()}
              />
            )}
          </div>
        </div>
      ) : (
        <div>User not logged in</div>
      )}
    </>
  );
};

export default LoginHistory;
