import axios from "axios";
const baseUrl = "https://service.nkomor.com";
// const baseUrl = "http://localhost:5000";

const apiRequest = async (method, endpoint, data = null) => {
  const config = {
    method: method,
    url: `${baseUrl}/${endpoint}`,
    headers: {
      "Content-Type": "application/json",
    },
    data: data,
  };
  const response = await axios(config);
  return response;
};

export default apiRequest;
