import React, { useState, useEffect } from "react";
import { Link, NavLink } from "react-router-dom";
import { SiShopware } from "react-icons/si";
import { MdOutlineCancel } from "react-icons/md";
import { TooltipComponent } from "@syncfusion/ej2-react-popups";

import { links } from "../data/dummy";
import { useStateContext } from "../contexts/ContextProvider";

const Sidebar = () => {
  const {
    activeMenu,
    setActiveMenu,
    screenSize,
    currentColor,
    isActive,
    setIsActive,
    userPreviledges,
  } = useStateContext();

  const activeLink =
    "flex items-center gap-3 pl-4 pt-3 pb-2.5  rounded-lg text-white text-md m-2 font-bold no-underline";
  const normalLink =
    "flex items-center gap-3 pl-4 pt-3 pb-2.5  rounded-lg text-md text-md text-[#D9D9D9] no-underline dark:text-gray-200 dark:hover:text-black hover:bg-light-gray m-2 hover:text-black";

  const handleCloseSideBar = () => {
    if (activeMenu && screenSize <= 900) {
      setActiveMenu(false);
    }
  };

  // useEffect(()=>{
  //   console.log(userPreviledges)
  //   console.log(links)
  // })

  return (
    <>
      {isActive === true ? (
        <div
          className="h-screen md:overflow-hidden overflow-auto
    md:hover:overflow-auto bg-[#1A9C9A] rounded-r-[50px]"
        >
          {activeMenu && (
            <>
              <div
                className="flex justify-between
      items-center"
              >
                <Link
                  to="/"
                  onClick={handleCloseSideBar}
                  className="items-center gap-3 ml-3 mt-4 flex text-2xl no-underline font-extrabold text-[#FFFFFF]"
                >
                  <span>Nkomor</span>
                </Link>
                <TooltipComponent content="Menu" position="BottomCenter">
                  <button
                    type="button"
                    onClick={() =>
                      setActiveMenu((prevActiveMenu) => !prevActiveMenu)
                    }
                    className="text-xl rounded-full p-3 hover:bg-light-gray
            mt-4 block md:hidden background-color-blue"
                  >
                    <MdOutlineCancel />
                  </button>
                </TooltipComponent>
              </div>
              <div className="">
                {links.map((item) => (
                  <div key={item.title}>
                    {item.links
                      .filter((link) => userPreviledges[link.name]) // Filter based on privileges
                      .map((link) => (
                        <NavLink
                          to={link.name === "log-out" ? "/" : `/${link.name}`}
                          key={link.name}
                          onClick={() => {
                            handleCloseSideBar();
                            if (link.name === "log-out") {
                              setIsActive(false);
                            }
                          }}
                          className={({ isActive }) =>
                            isActive ? activeLink : normalLink
                          }
                          style={({ isActive }) => ({
                            backgroundColor: isActive ? currentColor : "",
                          })}
                        >
                          {link.icon}
                          <span className="capitalize">{link.name}</span>
                        </NavLink>
                      ))}
                  </div>
                ))}
              </div>
            </>
          )}
        </div>
      ) : (
        ""
      )}
    </>
  );
};

export default Sidebar;
