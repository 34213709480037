import React, { useState } from "react";
import { Button, Form, Spinner } from "react-bootstrap";
import { FaFileExcel } from "react-icons/fa";
import "bootstrap/dist/css/bootstrap.min.css";
import {
  fetchRefillData,
  fetchSubscribersData,
  fetchUsersData,
  fetchInsuranceData,
  fetchPaystackData,
  fetchCouponData,
  fetchContactData,
} from "./apiRoute";
import { exportToCSV } from "../components/csvExport";
import { Header } from "../components";
import { Confirm, Info } from "../components/Alert.js";

const Export = () => {
  const [selectedData, setSelectedData] = useState("");
  const [loading, setLoading] = useState(false);

  const handleSelectChange = (e) => {
    setSelectedData(e.target.value);
  };

  const handleExport = async () => {
    if (!selectedData) {
      Info("No Data Selected", "Please select a data type.");
      return;
    }

    const confirmResult = await Confirm(
      "Confirm Export",
      "Do you want to export this data?"
    );
    if (confirmResult.isConfirmed) {
      setLoading(true);
      let data = [];
      try {
        switch (selectedData) {
          case "refill":
            data = await fetchRefillData();
            break;
          case "subscribers":
            data = await fetchSubscribersData();
            break;
          case "incomplete_users":
            data = await fetchUsersData();
            break;
          case "complete_users":
            data = await fetchUsersData();
            break;
          case "insurance":
            data = await fetchInsuranceData();
            break;
          case "paystack":
            data = await fetchPaystackData();
            break;
          case "coupons":
            data = await fetchCouponData();
            break;
          case "contacts":
            data = await fetchContactData();
            break;
          default:
            alert("Please select a data type");
            return;
        }

        if (selectedData === "incomplete_users") {
          const filteredIncomplete = data.data.data.user.filter(
            (user) => user.reg_complete !== 2
          );
          return exportToCSV(filteredIncomplete, `${selectedData}.csv`);
        } else if (selectedData === "complete_users") {
          const filteredComplete = data.data.data.user.filter(
            (user) => user.reg_complete === 2
          );
          return exportToCSV(filteredComplete, `${selectedData}.csv`);
        } else {
          exportToCSV(data.data.data.user, `${selectedData}.csv`);
        }
      } catch (error) {
        console.error("Error exporting data:", error);
        alert("An error occurred while exporting data. Please try again.");
      } finally {
        setLoading(false);
      }
    }
  };

  return (
    <>
      <div className="m-2 md:m-10 mt-24 p-4 md:p-10 bg-[#E6FFFD] rounded-3xl">
        <Header category="Export" title="Export Data" />
        <Form>
          <Form.Group controlId="dataSelect">
            <Form.Label>Select Data Type</Form.Label>
            <Form.Control
              as="select"
              value={selectedData}
              onChange={handleSelectChange}
              style={{ width: "15rem" }}
            >
              <option value="">Select...</option>
              <option value="refill">Refill Data</option>
              <option value="subscribers">Subscribers Data</option>
              <option value="incomplete_users">Incomplete_user Data</option>
              <option value="complete_users">Complete_user Data</option>
              <option value="insurance">Insurance Data</option>
              <option value="paystack">Paystack Data</option>
              <option value="coupons">Coupons Data</option>
              <option value="contacts">Contact Data</option>
            </Form.Control>
          </Form.Group>
          <Button
            onClick={handleExport}
            className="mt-3"
            disabled={loading}
            variant="info"
            style={{ color: "white" }}
          >
            {loading ? <Spinner animation="border" size="sm" /> : "Export CSV"}
          </Button>
        </Form>
      </div>
    </>
  );
};

export default Export;
