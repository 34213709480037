import React, { useState, useEffect } from "react";
import { Table, Button, Modal, Form, Spinner } from "react-bootstrap";
import BootstrapTable from "react-bootstrap-table-next";
import paginationFactory from "react-bootstrap-table2-paginator";
import { FaSpinner, FaTrash } from "react-icons/fa"; // Import FaTrash icon for delete action
import "bootstrap/dist/css/bootstrap.min.css";
import { fetchCouponData } from "./apiRoute";
import { Header } from "../components";
import { storeCoupon, deleteCoupon } from "./apiRoute";
import { useStateContext } from "../contexts/ContextProvider";
import { Confirm, Info } from "../components/Alert.js";

const Paystack = () => {
  const { isActive } = useStateContext();
  const [refillData, setRefillData] = useState([]);
  const [showModal, setShowModal] = useState(false);
  const [promoCode, setPromoCode] = useState("");
  const [couponType, setCouponType] = useState("");
  const [amount, setAmount] = useState("");
  const [loading, setLoading] = useState(false);
  const [searchTerm, setSearchTerm] = useState(""); // State for search term

  useEffect(() => {
    const fetchData = async () => {
      try {
        setLoading(true);
        const response = await fetchCouponData();
        setRefillData(response.data.data.user);
      } catch (error) {
        console.error("Error fetching data:", error);
      } finally {
        setLoading(false);
      }
    };
    fetchData();
  }, []);

  const totalCoupon = refillData.length;

  const couponUsed = refillData
    .filter((data) => data.used_by)
    .map((data) => data.used_by);
  const totalCouponUsed = couponUsed.length;

  const handleApplyCoupon = async () => {
    const confirmResult = await Confirm(
      "Confirm Task",
      `Do want to continue with this operation?`
    );
    if (confirmResult.isConfirmed) {
      try {
        setLoading(true);
        const response = await storeCoupon(promoCode, amount, couponType);
        const responseAgain = await fetchCouponData();
        setRefillData(responseAgain.data.data.user);
        Info("Success", response.data.message);
      } catch (error) {
        console.log(error);
        Info("Error", error.response.data.error);
      } finally {
        setShowModal(false);
        setLoading(false);
        setPromoCode("");
        setAmount("");
        setCouponType("");
      }
    }
  };

  const handleDeleteCoupon = async (promoCode) => {
    const confirmResult = await Confirm(
      "Confirm Delete",
      `You are about to delete promo code: ${promoCode}`
    );
    if (confirmResult.isConfirmed) {
      try {
        setLoading(true);
        const response = await deleteCoupon(promoCode);
        const responseAgain = await fetchCouponData();
        setRefillData(responseAgain.data.data.user);
        Info("Success", response.data.message);
      } catch (error) {
        console.log(error);
        Info("Error", error.response.data.error);
      } finally {
        setLoading(false);
      }
    }
  };

  const formatDate = (timestamp) => {
    if (timestamp <= 0) {
      return "";
    }

    const date = new Date(timestamp);
    const day = date.getDate();
    const month = date.toLocaleString("default", { month: "long" });
    const year = date.getFullYear();
    return `${day}-${month}-${year}`;
  };

  const columns = [
    {
      dataField: "count",
      text: "#",
      formatter: (cell, row, rowIndex) => rowIndex + 1,
      headerStyle: { width: "50px", backgroundColor: "#99E6E3" },
    },
    {
      dataField: "coupon",
      text: "Coupon",
      sort: true,
      headerStyle: {
        backgroundColor: "#99E6E3",
      },
    },
    {
      dataField: "amount",
      text: "Amount",
      sort: true,
      headerStyle: {
        backgroundColor: "#99E6E3",
      },
    },
    {
      dataField: "couponType",
      text: "Coupon Type",
      sort: true,
      headerStyle: {
        backgroundColor: "#99E6E3",
      },
    },
    {
      dataField: "used_by",
      text: "Used By",
      sort: true,
      headerStyle: {
        backgroundColor: "#99E6E3",
      },
    },
    {
      dataField: "status",
      text: "Status",
      sort: true,
      headerStyle: {
        backgroundColor: "#99E6E3",
      },
    },
    {
      dataField: "actions",
      text: "Actions",
      headerStyle: {
        backgroundColor: "#99E6E3",
      },
      formatter: (cell, row) =>
        loading ? (
          <Spinner animation="border" size="sm" />
        ) : (
          <Button
            variant="danger"
            onClick={() => handleDeleteCoupon(row.coupon)}
          >
            <FaTrash />
          </Button>
        ),
    },
  ];

  // Filter data based on search term
  const filteredData = refillData.filter(
    (user) =>
      user.coupon.toLowerCase().includes(searchTerm.toLowerCase()) ||
      user.used_by.toLowerCase().includes(searchTerm.toLowerCase())
  );

  return (
    <>
      {isActive === true ? (
        <div className="m-2 md:m-10 mt-24 p-4 md:p-10 bg-[#E6FFFD] rounded-3xl ">
          <Header category="Coupons" title="Coupons" />
          <div className="flex justify-start gap-10 mb-8">
            <div className="w-1/3 p-4 bg-white rounded-lg shadow-md font-semibold">
              Total Coupon <br />
              <span className="font-extrabold text-xl">{totalCoupon}</span>
            </div>
            <div className="w-1/3 p-4 bg-white rounded-lg shadow-md font-semibold">
              Total Coupon Used <br />
              <span className="font-extrabold text-xl">{totalCouponUsed}</span>
            </div>
          </div>
          <div style={{ marginBottom: "5px" }}>
            <Button
              variant="info"
              style={{ color: "white" }}
              onClick={() => setShowModal(true)}
            >
              Create Coupon
            </Button>
          </div>
          {/* Search input */}
          <Form.Control
            type="text"
            placeholder="Search..."
            className="mb-3"
            value={searchTerm}
            style={{ width: "300px", float: "right" }}
            onChange={(e) => setSearchTerm(e.target.value)}
          />
          <div>
            {loading ? (
              <Spinner animation="border" size="sm" />
            ) : (
              <BootstrapTable
                keyField="used_by"
                data={filteredData}
                columns={columns}
                bordered={false}
                pagination={paginationFactory()}
              />
            )}
          </div>

          <Modal show={showModal} onHide={() => setShowModal(false)}>
            <Modal.Header closeButton>
              <Modal.Title>Create Coupon</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <Form>
                <Form.Group controlId="promoCode">
                  <Form.Label>Promo Code</Form.Label>
                  <Form.Control
                    type="text"
                    placeholder="Enter promo code"
                    value={promoCode}
                    onChange={(e) => setPromoCode(e.target.value)}
                  />
                </Form.Group>
                <Form.Group controlId="promoCode">
                  <Form.Label>Coupon Type</Form.Label>
                  <Form.Select
                    aria-label="Default select example"
                    value={couponType}
                    onChange={(e) => setCouponType(e.target.value)}
                  >
                    <option value="">Select coupon type</option>
                    <option value="Value">Value</option>
                    <option value="%">%</option>
                  </Form.Select>
                </Form.Group>
                <Form.Group controlId="amount">
                  <Form.Label>Amount</Form.Label>
                  <Form.Control
                    type="number"
                    placeholder="Enter amount"
                    value={amount}
                    onChange={(e) => setAmount(e.target.value)}
                  />
                </Form.Group>
              </Form>
            </Modal.Body>
            <Modal.Footer>
              {loading ? (
                <Spinner animation="border" size="sm" />
              ) : (
                <>
                  <Button
                    variant="secondary"
                    onClick={() => setShowModal(false)}
                  >
                    Close
                  </Button>
                  <Button variant="primary" onClick={handleApplyCoupon}>
                    Apply Coupon
                  </Button>
                </>
              )}
            </Modal.Footer>
          </Modal>
        </div>
      ) : (
        <div>User not logged in</div>
      )}
    </>
  );
};

export default Paystack;
