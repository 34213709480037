import React, { useState, useEffect } from "react";
import { Button, Form, Spinner } from "react-bootstrap"; // Import Form component
import BootstrapTable from "react-bootstrap-table-next";
import paginationFactory from "react-bootstrap-table2-paginator";
import "bootstrap/dist/css/bootstrap.min.css";
import { fetchPharmacies, fetchDrugs, deleteDrug } from "./apiRoute";
import { Header } from "../components";
import { useStateContext } from "../contexts/ContextProvider";
import { Confirm, Info } from "../components/Alert";
import { FaTrash } from "react-icons/fa";

const Drugs = () => {
  const { isActive } = useStateContext();
  const [pharmacies, setPharmacies] = useState([]);
  const [drugs, setDrugs] = useState([]);
  const [searchTerm, setSearchTerm] = useState(""); // State for search term
  const [selectedFilter, setSelectedFilter] = useState("Equity Pharmacy"); // State for select option
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    const fetchData = async () => {
      try {
        setLoading(true);
        const [response1, response2] = await Promise.all([
          fetchPharmacies(),
          fetchDrugs(selectedFilter),
        ]);
        setPharmacies(response1.data.result);
        setDrugs(response2.data.result);
      } catch (error) {
        setDrugs([]);
        console.error("Error fetching data:", error);
      } finally {
        setLoading(false);
      }
    };
    fetchData();
  }, [selectedFilter]);

  const handleDeleteDrug = async (id) => {
    const confirmResult = await Confirm(
      "Delete Drug",
      "Do you want to delete this drug?"
    );
    if (confirmResult.isConfirmed) {
      try {
        setLoading(true);
        await deleteDrug(id);
        // Refetch the drugs data after successful deletion
        const updatedDrugsResponse = await fetchDrugs(selectedFilter);
        setDrugs(updatedDrugsResponse.data.result);
        Info("Delete Drug", "Drug deleted successfully");
      } catch (error) {
        console.log(error);
      } finally {
        setLoading(false);
      }
    }
  };

  const columns = [
    {
      dataField: "count",
      text: "#",
      formatter: (cell, row, rowIndex) => rowIndex + 1,
      headerStyle: { width: "50px", backgroundColor: "#99E6E3" },
    },
    {
      dataField: "name",
      text: "Name",
      headerStyle: {
        backgroundColor: "#99E6E3",
      },
    },
    {
      dataField: "product_information",
      text: "Information",
      headerStyle: {
        backgroundColor: "#99E6E3",
      },
    },
    {
      dataField: "price",
      text: "Price(₵)",
      sort: true,
      headerStyle: {
        backgroundColor: "#99E6E3",
      },
    },
    {
      dataField: "stock_quantity",
      text: "Quantity",
      sort: true,
      headerStyle: {
        backgroundColor: "#99E6E3",
      },
    },
    {
      dataField: "action",
      text: "Actions",
      headerStyle: {
        backgroundColor: "#99E6E3",
      },
      formatter: (cell, row) => (
        <>
          <Button
            variant="danger"
            onClick={() => handleDeleteDrug(row.id)}
            className="mr-2"
          >
            <FaTrash />
          </Button>
        </>
      ),
    },
  ];

  // Filter data based on search term
  const filteredData = drugs.filter(
    (drug) =>
      drug.name.toLowerCase().includes(searchTerm.toLowerCase()) ||
      drug.product_information
        .toLowerCase()
        .includes(searchTerm.toLowerCase()) ||
      drug.product_specification
        .toLowerCase()
        .includes(searchTerm.toLowerCase())
  );

  return (
    <>
      {isActive === true ? (
        <div className="m-2 md:m-10 mt-24 p-4 md:p-10 bg-[#E6FFFD] rounded-3xl">
          <Header title="Drugs" />
          <div className="flex justify-between mb-3">
            <Form.Select
              aria-label="Default select example"
              className="w-auto"
              value={selectedFilter} // Bind selected value to state
              onChange={(e) => setSelectedFilter(e.target.value)} // Update state on change
            >
              {pharmacies.map((pharmacy) => (
                <option value={pharmacy.store_name}>
                  {pharmacy.store_name}
                </option>
              ))}
            </Form.Select>
            <Form.Control
              type="text"
              placeholder="Search for Drug..."
              value={searchTerm}
              style={{ width: "300px", float: "right" }}
              onChange={(e) => setSearchTerm(e.target.value)}
            />
          </div>
          <div style={{ cursor: "pointer" }}>
            {loading ? (
              <Spinner animation="border" size="sm" />
            ) : (
              <BootstrapTable
                keyField="email"
                data={filteredData.sort((a, b) => b.id - a.id)}
                columns={columns}
                bordered={false}
                pagination={paginationFactory()}
              />
            )}
          </div>
        </div>
      ) : (
        <div>User not logged in</div>
      )}
    </>
  );
};

export default Drugs;
